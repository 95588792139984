import React from "react";
import { SInterier } from "./styles";
import { pageMotion } from "../../framer/motions";

const Interier = () => {
	return (
		<SInterier
			variants={pageMotion}
			initial='hidden'
			animate='show'
			exit='exit'
		>
			<div className='info-container'>
				<div className='title-container-page'>
					<h2>Интерьерные стеклосетки</h2>
				</div>

				<div className='content'>
					<div className='img-container'>
						<img
							src='/media/img/BautexSetka.webp'
							alt='Интерьерные стеклосетки'
						/>
					</div>
					<div className='text-container'>
						<p>
							Перед финишной отделкой стен различными материалами (обои,
							декоративная штукатурка, краска) необходимо добиться идеально
							ровной поверхности. От этого зависит качество итогового покрытия.
							Интерьерные стеклосетки применяются при малярных и штукатурных
							работах для армирования и защиты от трещин поверхностей внутри
							зданий и сооружений различного назначения.
						</p>
						<p>
							ПрофиМаляр – cтеклосетка малярная, защищает шпаклевочный слой от
							расслоения, деформации, возникновения трещин, нарушения
							целостности декоративных деталей, а также от неблагоприятных
							внешних воздействий. За счет небольшого веса, стеклосетка
							ПрофиМаляр удобна в работе.
						</p>
						<p>
							ПрофиШтукатур – cтеклосетка штукатурная, применяется для
							выравнивания несущих поверхностей, защиты штукатурных слоев от
							образования трещин, ремонта трещин на потолках и стенах, отделки
							проёмов окон и дверей.
						</p>
						<p>
							Интерьерные стеклосетки ПрофиМаляр и ПрофиШтукатур влагоустойчивы,
							хорошо выдерживают воздействие температур, нетоксичны, обладают
							противоударным эффектом.
						</p>
						<p>
							Стеклосетки применяются с различными материалами: гипсокартон,
							бетон, ДВП, ДСП, кирпич и другие.
						</p>
					</div>
				</div>

				<div className='details-container'>
					<ul>
						<li>
							<p>
								Разрывная нагрузка: <br /> 500-700 H / 5 см{" "}
							</p>
						</li>
						<li>
							<p>
								Ширина рулона: <br /> 1 м
							</p>
						</li>
						<li>
							<p>
								Длина рулона: <br /> 10 - 50 м
							</p>
						</li>
						<li>
							<p>
								Размер ячейки: <br /> 2 - 5 мм
							</p>
						</li>
					</ul>
				</div>
			</div>

			<div className='sorts'>
				<div className='profimal'>
					<div className='title-container'>
						<h3>Профималяр</h3>
					</div>
					<ul>
						<li>
							<p>Прочность на разрыв, H / 5см не мнеее: 500 / 500</p>
						</li>
						<li>
							<p>Плотность, г/ м ²: 48 ± 7%</p>
						</li>
						<li>
							<p>Размер ячейки, мм: 2 х 2</p>
						</li>
						<li>
							<p>Длина рулона, м: 40</p>
						</li>
					</ul>
				</div>
				<div className='profishtuk'>
					<div className='title-container'>
						<h3>Профиштукатур</h3>
					</div>
					<ul>
						<li>
							<p>Прочность на разрыв, H / 5см не мнеее: 700 / 700</p>
						</li>
						<li>
							<p>Плотность, г/ м ²: 56 ± 7%</p>
						</li>
						<li>
							<p>Размер ячейки, мм: 5 х 5</p>
						</li>
						<li>
							<p>Длина рулона, м: 40</p>
						</li>
					</ul>
				</div>
			</div>
		</SInterier>
	);
};

export default Interier;
