import styled from "styled-components";
import { v, b, color, t } from "../../../styles/variables";

export const SFooter = styled.footer`
	background-color: ${color.blue};
	color: ${color.lightBg};
	min-height: 5vh;
	padding: 5% ${v.smallDist};

	.footer-container {
		display: grid;
		grid-gap: ${v.smallDist};
	}

	.socials {
		display: flex;
		column-gap: ${v.mediumDist};
		a {
			background: ${color.background};
			border-radius: 50%;
			padding: 0.2rem;
			display: flex;
			align-items: center;
			transition: ${t.fastSp};

			&:hover {
				background: ${color.link};
				color: ${color.background};
			}
		}
	}

	h4 {
		font-size: 0.7rem;
	}

	@media ${b.medium} {
		padding: 2% ${v.mainMdPd};
	}

	@media ${b.large} {
		h4 {
			font-size: 0.9rem;
		}
	}
`;
