import { CheckBoxOutlined } from "@material-ui/icons";
import React from "react";
import { SFlizelin } from "./styles";
import { flizelinState } from "../../data/prodState";
import BigCard from "../../components/Cards/BigCard";
import { useState } from "react";
import { pageMotion } from "../../framer/motions";

const Flizelin = () => {
	const [flizelin] = useState(flizelinState);
	return (
		<SFlizelin
			variants={pageMotion}
			initial='hidden'
			animate='show'
			exit='exit'
		>
			<div className='info-container'>
				<div className='title-container-page'>
					<h2>Флизелиновый холст</h2>
				</div>

				<div className='content'>
					<div className='img-container'>
						<img src='/media/img/holst_setka.jpg' alt='Флизелиновая сетка' />
					</div>
					<div className='text-container'>
						<p>
							Флизелиновый холст — это прессованное полотно из длинноволокнистой
							целлюлозы, синтетических волокон, связанных между собой
							полимерными материалами. Ремонтный флизелин обладает более
							значительной прочностью и долговечностью чем бумага, благодаря
							высокому содержанию волокон, воздухопроницаем, не растягивается и
							не дает усадки, предотвращает распространение трещин при намокании
							и последующем высыхании.
						</p>
						<ul>
							<li>
								<CheckBoxOutlined />
								<p>
									Возможно армирование стен в помещениях не давших усадку или в
									которых возможны механические повреждения
								</p>
							</li>
							<li>
								<CheckBoxOutlined />
								<p>
									Подходит для любых поверхностей: бетон, кирпич, гипсокартон,
									ДСП, пластик или метал
								</p>
							</li>
							<li>
								<CheckBoxOutlined />
								<p>Препятствует образованию плесени</p>
							</li>
							<li>
								<CheckBoxOutlined />
								<p>Возможно многократное окрашивание</p>
							</li>
							<li>
								<CheckBoxOutlined />
								<p>Можно использовать щелочные моющие средства, щётки</p>
							</li>
							<li>
								<CheckBoxOutlined />
								<p>
									Непористая структура, нет среды для появления микроэлементов
								</p>
							</li>
							<li>
								<CheckBoxOutlined />
								<p>Обеспечивает чистые и герметичные поверхности</p>
							</li>
							<li>
								<CheckBoxOutlined />
								<p>Прост в использовании</p>
							</li>
							<li>
								<CheckBoxOutlined />
								<p>Пропускает воздух и пар</p>
							</li>
							<li>
								<CheckBoxOutlined />
								<p>Легко моется и чистится</p>
							</li>
						</ul>
					</div>
				</div>
				<div className='advantages'>
					<div className='title-container'>
						<h3>Преимущества и сфера применения</h3>
					</div>
					<p>
						Флизелиновый холст маскирует трещины, швы, русты, выравнивает и
						укрепляет поверхность стен и потолков перед покраской. Снижает
						расход краски, заменяет финишную шпатлевку перед покраской.
						Флизелиновый холст клеится на все виды штукатурок, пористый бетон,
						гипсокартон, бумагу, дерево и панели ДСП, стеклопластик, пластиковые
						поверхности и даже на старые обои любых типов или старую краску.
					</p>
					<p>
						Флизелиновый холст применяется на гипсокартоне и ДСП (ДВП), на
						бетонных, кирпичных, деревянных и металлических основаниях.
						Флизелиновым холстом можно оклеивать и потолок и стены. После
						ремонта штукатурка часто дает мелкие трещины, а материал армирует
						поверхность, предотвращая их появление, скрывает русты, швы между
						панелями, создает ровную гладкую поверхность. Флизелиновый холст
						универсальный материал, он отлично подходит для покраски, а так же
						может использоваться как основа для наклеивания обоев. Не требует
						дальнейшей обработки штукатуркой. Применяется как для ремонта так и
						в отделке новых помещений.
					</p>
				</div>
			</div>
			<div className='product-list'>
				{flizelin.map((fliz) => (
					<BigCard
						name={fliz.name}
						image={fliz.img}
						mass={fliz.mass}
						strengthFirst={fliz.strengthFirst}
						strengthSecond={fliz.strengthSecond}
						whiteness={fliz.whiteness}
						opacity={fliz.opacity}
						key={fliz.name}
					/>
				))}
			</div>
		</SFlizelin>
	);
};

export default Flizelin;
