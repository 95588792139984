import React from "react";
import { SWallGlue } from "./styles";
import { pageMotion } from "../../framer/motions";
import { InfoOutlined } from "@material-ui/icons";

const Wallglue = () => {
	return (
		<SWallGlue
			variants={pageMotion}
			initial='hidden'
			animate='show'
			exit='exit'
		>
			<div className='info-container'>
				<div className='title-container-page'>
					<h2>
						Обойный клей <span className='special'>ОКлей</span>
					</h2>
				</div>
				<p>
					Уникальная линейка обойного клея БауТекс премиум качества по доступной
					цене.
				</p>
				<p>
					Основной компонент клея – картофельный крахмал из Голландии. Селекция
					картофеля (для дальнейшей переработки в крахмал) проводится особым
					образом по старинным голландским традициям без использования генной
					модификации.
				</p>
				<div className='content'>
					<div className='img-container'>
						<img src='/media/img/glueUniversal.webp' alt='glue' />
					</div>
					<div className='text-container'>
						<h3 className='special'>Оклей! Универсал</h3>
						<ul>
							<li>
								<InfoOutlined />
								<p>Универсальный клей для различных видов обоев.</p>
							</li>
							<li>
								<InfoOutlined />
								<p>Легко растворяется в холодной воде за 3 минуты.</p>
							</li>
							<li>
								<InfoOutlined />
								<p>Внешний вид: мелкие хлопья белого цвета</p>
							</li>
							<li>
								<InfoOutlined />
								<p>Состав: модифицированный крахмал, биоцид</p>
							</li>
							<li>
								<InfoOutlined />
								<p>
									Защита от бактерий: готовая паста стабильна в течение недели.
								</p>
							</li>
							<li>
								<InfoOutlined />
								<p>Оклей! фасуется в удобные пакеты по 200 и 500 г.</p>
							</li>
						</ul>
					</div>
				</div>
				<div className='content'>
					<div className='img-container'>
						<img className='extra' src='/media/img/glueExtra.webp' alt='glue' />
					</div>
					<div className='text-container'>
						<h3 className='special'>ОКлей! Экстра</h3>
						<p>
							Рецептуры данного клея для различных видов обоев были разработаны
							в Научном Центре БауТекс совместно с голландскими специалистами.
						</p>
						<p>
							ОКлей! Экстра обладает прочностью клеевого шва в два раза выше по
							сравнению со стандартным универсальным клеем. Для каждого вида
							обоев мы подобрали состав ОКлея! Экстра с учетом особенностей его
							нанесения:
						</p>
						<ul>
							<li>
								<InfoOutlined />
								<p>для бумажных обоев — на сами обои</p>
							</li>
							<li>
								<InfoOutlined />
								<p>
									для стеклотканевых — на стену (клей с уменьшенным
									каплепадением)
								</p>
							</li>
						</ul>
						<p>
							ОКлей! Экстра легко растворяется в холодной воде в течение 3 мин.
						</p>
						<p>Внешний вид: мелкие хлопья белого цвета</p>
						<p>
							Состав: специально разработанные смеси модифицированных крахмалов,
							антибактериальная и противогрибковая добавки.
						</p>
						<p>
							Защита от бактерий и грибка: готовая паста стабильна в течение
							недели.
						</p>
						<p>ОКлей! Экстра упакован в картонные пачки по 250 г.</p>
						<h4>Виды:</h4>
						<ul>
							<li>
								<InfoOutlined />
								<p>ОКлей!Экстра стеклотканевые обои</p>
							</li>
							<li>
								<InfoOutlined />
								<p>ОКлей!Экстра виниловые обои</p>
							</li>
							<li>
								<InfoOutlined />
								<p>ОКлей!Экстра бумажные обои</p>
							</li>
							<li>
								<InfoOutlined />
								<p>ОКлей!Экстра флизелин</p>
							</li>
							<li>
								<InfoOutlined />
								<p>ОКлей!Экстра универсал</p>
							</li>
						</ul>
					</div>
				</div>
				<div className='content'>
					<div className='img-container'>
						<img src='/media/img/glueProf.webp' alt='glue' />
					</div>
					<div className='text-container'>
						<h3 className='special'>Оклей! Профессионал</h3>
						<p>
							Профессиональный готовый к применению клей для любых видов обоев.
						</p>
						<p>
							Для производства готового клея используется высококачественное
							европейское сырье и вода питьевого качества.
						</p>
						<p>
							Оптимальное соотношение: модифицированный крахмал и
							поливинилацетатная дисперсия обеспечивает надежный и качественный
							результат при оклеивании стен стеклотканевыми и другими видами
							тяжелых обоев.
						</p>
						<p>Содержит биоцид и фунгицид для защиты от бактерий и плесени.</p>
						<p>Клей прозрачен после высыхания, не оставляет пятен!</p>
						<p>
							Оклей! Профессионал фасуется в ведра по 5 и 10 кг. прямоугольной
							формы, что обеспечивает удобство работы при нанесении клея.
						</p>
					</div>
				</div>
			</div>
		</SWallGlue>
	);
};

export default Wallglue;
