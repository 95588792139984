import React, { useEffect } from "react";
import { SMainContainer } from "./styles";
import { titleMotion, fadeInMotion } from "../../../../framer/motions";
import { motion } from "framer-motion";
import Link from "react-scroll/modules/components/Link";
import { useState } from "react";

const MainContainer = () => {
	const images = ["RomeMain", "TokyoMain", "DublinMain", "PragueMain"];
	const [count, setCount] = useState(0);
	const [img, setImg] = useState(images[count]);
	useEffect(() => {
		const imgId = setInterval(() => {
			setCount(count + 1);
			if (count >= 3) {
				setCount(0);
			}
			setImg(images[count]);
		}, 10000);
		return () => {
			clearInterval(imgId);
		};
	});

	return (
		<SMainContainer>
			<div className='main-content'>
				<motion.h1 variants={titleMotion} className='main-title'>
					Mo'jiza Tosh
				</motion.h1>
				<div className='pic-container mobile'>
					<div className='main-img'>
						<motion.img
							key={img}
							variants={fadeInMotion}
							src={`/media/img/${img}.webp`}
							alt='Rome'
						/>
					</div>
				</div>
				<div className='products-container'>
					<div className='product-list'>
						<motion.div variants={fadeInMotion} className='product-block'>
							<Link to='facade' smooth={true} duration={1000}>
								Фасадные стеклосетки крепикс
							</Link>
						</motion.div>
						<motion.div variants={fadeInMotion} className='product-block'>
							<Link to='fiberglass' smooth={true} duration={1000}>
								Стеклохолсты
							</Link>
						</motion.div>
						<motion.div variants={fadeInMotion} className='product-block'>
							<Link to='interline' smooth={true} duration={1000}>
								Флизелиновые холсты
							</Link>
						</motion.div>
						<motion.div variants={fadeInMotion} className='product-block'>
							<Link to='fiberwall' smooth={true} duration={1000}>
								Стеклотканевые обои
							</Link>
						</motion.div>
						<motion.div variants={fadeInMotion} className='product-block'>
							<Link to='interier' smooth={true} duration={1000}>
								Интерьерные сетки
							</Link>
						</motion.div>
						<motion.div variants={fadeInMotion} className='product-block'>
							<Link to='jacquard' smooth={true} duration={1000}>
								Жаккардовые обои Bautex Design
							</Link>
						</motion.div>
						<motion.div
							variants={fadeInMotion}
							className='product-block empty'
						></motion.div>
						<motion.div variants={fadeInMotion} className='product-block'>
							<Link to='wallglue' smooth={true} duration={1000}>
								Обойный клей
							</Link>
						</motion.div>
						<motion.div
							variants={fadeInMotion}
							className='product-block empty'
						></motion.div>
					</div>
				</div>
			</div>
			<div className='pic-container large'>
				<div className='main-img'>
					<motion.img
						key={img}
						variants={fadeInMotion}
						src={`/media/img/${img}.webp`}
						alt='Rome'
					/>
				</div>
			</div>
		</SMainContainer>
	);
};

export default MainContainer;
