import React from "react";
import { useState } from "react";
import { SSmallCard } from "./styles";
import { AnimatePresence, motion } from "framer-motion";
import { fadeInMotion } from "../../framer/motions";

const SmallCard = ({ name, hq, image, density, rapport }) => {
	const [zoom, setZoom] = useState(false);
	const screenLock = () => {
		if (zoom) {
			document.body.style.overflowY = "hidden";
		} else {
			document.body.style.overflowY = "auto";
		}
	};
	document.addEventListener("click", screenLock);

	return (
		<SSmallCard onClick={() => setZoom(!zoom)}>
			<div className='img-container'>
				<img src={image} alt={name} />
			</div>
			<p className='product-name'>{name}</p>
			<p className='density'>
				{density} <span>Плотность</span>
			</p>
			{rapport ? (
				<p className='rapport'>
					{rapport} <span>Раппорт</span>
				</p>
			) : (
				""
			)}
			<AnimatePresence>
				{zoom && (
					<motion.div
						key={name}
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						className='full-image-container'
					>
						<img src={hq} alt={name} />
					</motion.div>
				)}
			</AnimatePresence>
		</SSmallCard>
	);
};

export default SmallCard;
