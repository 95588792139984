import React, { useState } from "react";
import { SJacquard } from "./styles";
import { bautexDesignState } from "../../data/prodState";
import SmallCard from "../../components/Cards/SmallCard";
import { pageMotion } from "../../framer/motions";

const Jacquard = () => {
	const [jacquard] = useState(bautexDesignState);
	return (
		<SJacquard
			variants={pageMotion}
			initial='hidden'
			animate='show'
			exit='exit'
		>
			<div className='info-container'>
				<div className='title-container-page'>
					<h2>Жаккардовые обои из кварцевой нити BauTex Design</h2>
				</div>

				<div className='content'>
					<div className='text-container-alt'>
						<p>
							BauTex Design — это инновационное решение для стен, которое
							сочетает в себе функциональность, экологичность и эстетику тканных
							фактур.
						</p>
						<p>
							Обои представляют собой особо прочный тканный материал, который
							позволяет стенам «дышать» и препятствует образованию плесени и
							грибка.
						</p>
						<p>
							Жаккардовые обои BauTex Design создаются из специальной кварцевой
							нити, которая на 100% состоит их экологически чистых материалов и
							безвредна даже для детей и людей с различными аллергическими
							реакциями. Обои ткутся на специальных жаккардовых станках, что
							позволяет сделать индивидуальный рисунок по вашему заказу.
						</p>
						<p>
							Да, мы можем сделать для вас индивидуальную фактуру с вашим
							рисунком, логотипом компании или элементами айдентики. Минимальный
							объем для заказа таких обоев – 300 кв.м. Это 12 рулонов длиной 25
							метров.
						</p>
					</div>
				</div>

				<div className='details-container'>
					<ul>
						<li>
							<p>
								Нетоксичность/огнестойкость: <br /> Класс горючести Г1
							</p>
						</li>
						<li>
							<p>
								Плотность: <br /> от 255 до 280 г / м ²
							</p>
						</li>
						<li>
							<p>
								Ширина рулона: <br /> 1 м
							</p>
						</li>
						<li>
							<p>
								Длина рулона: <br /> 25 м
							</p>
						</li>
					</ul>
				</div>
			</div>
			<div className='product-list'>
				{jacquard.map((jac) => (
					<SmallCard
						name={jac.name}
						image={jac.img}
						hq={jac.hq}
						density={jac.density}
						rapport={jac.rapport}
						key={jac.name}
					/>
				))}
			</div>
		</SJacquard>
	);
};

export default Jacquard;
