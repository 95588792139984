import React from "react";
import { SProductContainer } from "./styles";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useScroll } from "../../../../components/ScrollView/useScroll";
import { fadeInMotion } from "../../../../framer/motions";

const ProductContainer = () => {
	const [element, controls] = useScroll();
	const [element2, controls2] = useScroll();
	const [element3, controls3] = useScroll();
	const [element4, controls4] = useScroll();
	const [element5, controls5] = useScroll();
	const [element6, controls6] = useScroll();
	const [element7, controls7] = useScroll();
	return (
		<SProductContainer>
			<div className='overlay-left'></div>
			<div ref={element} id='facade' className='product facade'>
				<motion.div
					variants={fadeInMotion}
					initial='hidden'
					animate={controls}
					className='preview-img'
				>
					<img src='/media/img/krepixMain.webp' alt='facade' />
				</motion.div>
				<motion.div
					variants={fadeInMotion}
					initial='hidden'
					animate={controls}
					className='info-container'
				>
					<motion.div variants={fadeInMotion} className='bg-small'></motion.div>
					<h2>Фасадные стеклосетки Крепикс</h2>
					<p>
						Армирование штукатурных составов на наружных и внутренних
						поверхностях строительных конструкций и в системах фасадных
						теплоизоляционных композиционных.
					</p>
					<div className='link-container'>
						<Link className='product-link' to='facade'>
							Подробнее
						</Link>
					</div>
				</motion.div>
			</div>
			<div ref={element2} id='fiberglass' className='product fiberglass'>
				<motion.div
					variants={fadeInMotion}
					initial='hidden'
					animate={controls2}
					className='preview-img'
				>
					<img src='/media/img/stekloMain.webp' alt='fiber' />
				</motion.div>
				<motion.div
					variants={fadeInMotion}
					initial='hidden'
					animate={controls2}
					className='info-container'
				>
					<h2>Стеклохолсты</h2>
					<p>
						Декоративный армирующий материал под шпаклевку или покраску,
						основная функция которого заключается в защите поверхностей от
						трещин.
					</p>
					<div className='link-container'>
						<Link className='product-link' to='fiberglass'>
							Подробнее
						</Link>
					</div>
				</motion.div>
			</div>
			<div ref={element3} id='interline' className='product interline'>
				<motion.div
					variants={fadeInMotion}
					initial='hidden'
					animate={controls3}
					className='preview-img'
				>
					<img src='/media/img/flizelinMain.webp' alt='interline' />
				</motion.div>
				<motion.div
					variants={fadeInMotion}
					initial='hidden'
					animate={controls3}
					className='info-container'
				>
					<motion.div variants={fadeInMotion} className='bg-big'></motion.div>
					<h2>Флизелиновые холсты</h2>
					<p>
						Маскирует трещины, швы, русты, выравнивает и укрепляет поверхность
						стен и потолков перед покраской.
					</p>
					<div className='link-container'>
						<Link className='product-link' to='flizelin'>
							Подробнее
						</Link>
					</div>
				</motion.div>
			</div>
			<div ref={element4} id='fiberwall' className='product fiberwall'>
				<motion.div
					variants={fadeInMotion}
					initial='hidden'
					animate={controls4}
					className='preview-img'
				>
					<img src='/media/img/fiberMain.webp' alt='fiberwall' />
				</motion.div>
				<motion.div
					variants={fadeInMotion}
					initial='hidden'
					animate={controls4}
					className='info-container'
				>
					<h2>Стеклотканевые обои</h2>
					<p>
						Декоративное настенное покрытие на основе природных компонентов для
						отделки стен любых помещений
					</p>
					<div className='link-container'>
						<Link className='product-link' to='fiberwall'>
							Подробнее
						</Link>
					</div>
				</motion.div>
			</div>
			<div ref={element5} id='interier' className='product interier'>
				<motion.div
					variants={fadeInMotion}
					initial='hidden'
					animate={controls5}
					className='preview-img'
				>
					<img src='/media/img/interernaya_setka.jpg' alt='interier' />
				</motion.div>
				<motion.div
					variants={fadeInMotion}
					initial='hidden'
					animate={controls5}
					className='info-container'
				>
					<motion.div
						variants={fadeInMotion}
						className='bg-medium'
					></motion.div>
					<h2>Интерьерные сетки</h2>
					<p>
						Армирование и защита от трещин поверхностей внутри зданий и
						сооружений различного назначения при малярных и штукатурных работах
					</p>
					<div className='link-container'>
						<Link className='product-link' to='interier'>
							Подробнее
						</Link>
					</div>
				</motion.div>
			</div>
			<div ref={element6} id='jacquard' className='product jacquard'>
				<motion.div
					variants={fadeInMotion}
					initial='hidden'
					animate={controls6}
					className='preview-img'
				>
					<img src='/media/img/jaqcuardMain.webp' alt='jacquard' />
				</motion.div>
				<motion.div
					variants={fadeInMotion}
					initial='hidden'
					animate={controls6}
					className='info-container'
				>
					<h2>Жаккардовые обои BauTex Design</h2>
					<p>
						Инновационное решение для стен, которое сочетает в себе
						функциональность, экологичность и эстетику тканных фактур
					</p>
					<div className='link-container'>
						<Link className='product-link' to='jacquard'>
							Подробнее
						</Link>
					</div>
				</motion.div>
			</div>
			<div ref={element7} id='wallglue' className='product wallglue'>
				<motion.div
					variants={fadeInMotion}
					initial='hidden'
					animate={controls7}
					className='preview-img transparent'
				>
					<img src='/media/img/glueUniversal.webp' alt='wallglue' />
				</motion.div>
				<motion.div
					variants={fadeInMotion}
					initial='hidden'
					animate={controls7}
					className='info-container'
				>
					<motion.div variants={fadeInMotion} className='bg-small'></motion.div>
					<h2>Обойный клей ОКлей</h2>
					<p>
						Обойный клей ОКлей Уникальная линейка обойного клея БауТекс премиум
						качества по доступной цене
					</p>
					<div className='link-container'>
						<Link className='product-link' to='wallglue'>
							Подробнее
						</Link>
					</div>
				</motion.div>
			</div>
		</SProductContainer>
	);
};

export default ProductContainer;
