import { CheckBoxOutlined } from "@material-ui/icons";
import React from "react";
import { Link } from "react-router-dom";
import { SFiberwall } from "./styles";
import { pageMotion } from "../../framer/motions";

const Fiberwall = () => {
	return (
		<SFiberwall
			variants={pageMotion}
			initial='hidden'
			animate='show'
			exit='exit'
		>
			<div className='info-container'>
				<div className='title-container-page'>
					<h2>Стеклотканевые обои</h2>
				</div>
				<div className='content'>
					<div className='text-container-alt'>
						<p>
							Стеклотканевые обои создадут индивидуальный стиль вашего дома.
							Эксклюзивность этого материала в его структуре — это тканное
							полотно. Выбирая стеклотканевые обои, вы можете быть уверены в
							здоровье вашей семьи: продукт сделан из природных материалов,
							экологически чистый.
						</p>
						<p>
							Благодаря тканной структуре материала, между волокнами ткани
							циркулирует воздух. Стеклотканевые обои дышат и не образуют грибок
							и плесень, балансируют влажность в помещении.
						</p>
						<p>
							Особая объемная структура. Стеклотканевые обои имеют выраженный
							рельефный рисунок, подчеркивающий, благодаря игре света и тени,
							роскошь вашего интерьера.
						</p>
						<p>
							Благодаря своей прочной структуре, стеклотканевые обои сложно
							повредить, при этом они легко поддаются влажной очистке. Это
							надежный материал, который будет радовать вас долгие годы.
						</p>
					</div>
				</div>
				<div className='wallper-links'>
					<div className='link slimtex'>
						<Link to='/slimtex'>
							<img src='/media/img/wallperCard1.png' alt='Slimtex' />
						</Link>
					</div>
					<div className='link profitex'>
						<Link to='/profitex'>
							<img src='/media/img/wallperCard2.png' alt='Profitex' />
						</Link>
					</div>
					<div className='link walltex'>
						<Link to='/walltex'>
							<img src='/media/img/wallperCard3.png' alt='Walltex' />
						</Link>
					</div>
					<div className='link design'>
						<Link to='/jacquard'>
							<img src='/media/img/wallperCard4.png' alt='Design' />
						</Link>
					</div>
				</div>
				<div className='advantages'>
					<div className='title-container'>
						<h3>Преимущества стеклотканевых обоев</h3>
					</div>
					<div className='advantage-list'>
						<div className='aesthetic'>
							<h4>Эстетично</h4>
							<ul>
								<li>
									<CheckBoxOutlined />
									<p>Волнующий рельеф, роскошь тканого материала</p>
								</li>
								<li>
									<CheckBoxOutlined />
									<p>Приятные на ощупь</p>
								</li>
								<li>
									<CheckBoxOutlined />
									<p>Трехмерность. Игра света и тени</p>
								</li>
								<li>
									<CheckBoxOutlined />
									<p>Разнообразие и эксклюзивность рисунков</p>
								</li>
								<li>
									<CheckBoxOutlined />
									<p>Дизайнерские решения</p>
								</li>
								<li>
									<CheckBoxOutlined />
									<p>Выбор индивидуального цвета</p>
								</li>
							</ul>
						</div>
						<div className='functional'>
							<h4>Функционально</h4>
							<ul>
								<li>
									<CheckBoxOutlined />
									<p>
										Прочность: устойчивы к повреждениям от трения, когтей
										животных
									</p>
								</li>
								<li>
									<CheckBoxOutlined />
									<p>Защита от трещин, выравнивание мелких неровностей</p>
								</li>
								<li>
									<CheckBoxOutlined />
									<p>Нетоксичность и огнестойкость. Класс горючести Г1</p>
								</li>
								<li>
									<CheckBoxOutlined />
									<p>Высокая износостойкость</p>
								</li>
								<li>
									<CheckBoxOutlined />
									<p>Влажная очистка</p>
								</li>
							</ul>
						</div>
						<div className='profitable'>
							<h4>Выгодно</h4>
							<ul>
								<li>
									<CheckBoxOutlined />
									<p>Долговечность в сравнении с остальными видами обоев</p>
								</li>
								<li>
									<CheckBoxOutlined />
									<p>Оптимальная совокупная стоимость эксплуатации</p>
								</li>
								<li>
									<CheckBoxOutlined />
									<p>
										Всего один раз в 15 лет Вам нужно подготовить и оклеить
										стены
									</p>
								</li>
								<li>
									<CheckBoxOutlined />
									<p>Обои можно перекрашивать до 15 раз</p>
								</li>
								<li>
									<CheckBoxOutlined />
									<p>Гарантия качества</p>
								</li>
							</ul>
						</div>
						<div className='ecological'>
							<h4>Экологично</h4>
							<ul>
								<li>
									<CheckBoxOutlined />
									<p>Сделаны из природных материалов</p>
								</li>
								<li>
									<CheckBoxOutlined />
									<p>Безопасны для здоровья</p>
								</li>
								<li>
									<CheckBoxOutlined />
									<p>Дышат. Балансируют влажность в помещении</p>
								</li>
								<li>
									<CheckBoxOutlined />
									<p>Не образуют грибка и плесени</p>
								</li>
								<li>
									<CheckBoxOutlined />
									<p>Не имеют запаха</p>
								</li>
								<li>
									<CheckBoxOutlined />
									<p>Диэлектрик</p>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div className='useful-sides'>
					<div className='useful'>
						<img src='/media/img/useful1.png' alt='Преимущества' />
						<p>
							Благодаря тканной структуре материала, между волокнами ткани
							циркулирует воздух. Стеклотканевые обои дышат и не образуют грибка
							и плесени, балансируя влажность в помещении.
						</p>
					</div>
					<div className='useful'>
						<img src='/media/img/useful2.png' alt='Преимущества' />
						<p>
							Благодаря тканной структуре материала, между волокнами ткани
							циркулирует воздух. Стеклотканевые обои дышат и не образуют грибка
							и плесени, балансируя влажность в помещении.
						</p>
					</div>
					<div className='useful'>
						<img src='/media/img/useful3.png' alt='Преимущества' />
						<p>
							Благодаря своей прочной структуре, стеклотканевые обои сложно
							повредить, при этом они легко поддаются влажной очистке. Это
							долговечный материал, который будет радовать вас долгие годы.
						</p>
					</div>
				</div>
				<div className='application-areas'>
					<div className='title-container'>
						<h3>Область применения</h3>
					</div>
					<div className='areas'>
						<div className='area'>
							<img src='/media/img/area1.png' alt='Область применения' />
							<p>Лечебно-профилактические учреждения</p>
						</div>
						<div className='area'>
							<img src='/media/img/area2.png' alt='Область применения' />
							<p>Дошкольные и учебные заведения</p>
						</div>
						<div className='area'>
							<img src='/media/img/area3.png' alt='Область применения' />
							<p>Производственные и жилые здания и сооружения</p>
						</div>
						<div className='area'>
							<img src='/media/img/area4.png' alt='Область применения' />
							<p>Аэропорты и ж/д вокзалы</p>
						</div>
					</div>
				</div>
			</div>
		</SFiberwall>
	);
};

export default Fiberwall;
