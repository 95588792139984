import React from "react";
import { SBigCard } from "./styles";

const BigCardS = ({ image, name, mass, massLose, size1, size2 }) => {
	return (
		<SBigCard>
			<div className='img-container'>
				<img src={image} alt='fiber' />
			</div>
			<div className='title-container'>
				<h3>{name}</h3>
			</div>

			<ul className='card-details'>
				<li>
					<p>Поверхностная масса, г / м ² :</p> <span>{mass}</span>
				</li>
				<li>
					<p>Потеря массы при прокаливании, % :</p> <span>{massLose}</span>
				</li>
				<li>
					<p>Предел прочности на разрыв, Н / 50мм, не менее (Основа):</p>
					<span>{size1}</span>
				</li>
				<li>
					<p>Предел прочности на разрыв, Н / 50мм, не менее (Уток):</p>
					<span>{size2}</span>
				</li>
			</ul>
		</SBigCard>
	);
};

export default BigCardS;
