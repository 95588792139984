import React, { useState } from "react";
import { SFacade } from "./styles";
import { krepixState } from "../../data/prodState";
import BigCardF from "../../components/Cards/BigCardF";
import { pageMotion } from "../../framer/motions";

const Facade = () => {
	const [krepix] = useState(krepixState);
	return (
		<SFacade variants={pageMotion} initial='hidden' animate='show' exit='exit'>
			<div className='info-container'>
				<div className='title-container-page'>
					<h2>Фасадные стеклосетки Крепикс</h2>
				</div>
				<div className='content'>
					<div className='img-container'>
						<img
							className='facade-img'
							src='/media/img/steklosetka-info.jpg'
							alt='facade'
						/>
					</div>
					<div className='text-container'>
						<p>
							Фасадные стеклосетки Крепикс представляют собой тканые полотна с
							прямоугольными ячейками фиксированных размеров. Сетки вырабатывают
							перевивочным переплетением стеклянных нитей и стеклянных ровингов.
						</p>
						<p>
							Стеклосетки Крепикс пропитываются специальным раствором для
							придания щелочестойкости и используются для армирования
							штукатурных составов в фасадных системах «мокрого типа».
							Стеклосетки в зависимости от плотности, размера ячеек и вида
							полимерной пропитки обладают различными характеристиками и
							применяются для армирования наружных и внутренних поверхностей
							ограждающих строительных конструкций и в системах фасадных
							теплоизоляционных композиционных с наружными штукатурными слоями.
						</p>
					</div>
				</div>
				<div className='details-container'>
					<ul>
						<li>
							<p>
								Разрывная нагрузка: <br /> 1300-3600 H / 5см
							</p>
						</li>
						<li>
							<p>
								Ширина рулона: <br /> 1м
							</p>
						</li>
						<li>
							<p>
								Длина рулона: <br /> 25 - 50м
							</p>
						</li>
						<li>
							<p>
								Размер ячейки: <br /> 3 - 8,5 мм
							</p>
						</li>
					</ul>
				</div>
			</div>
			<div className='product-list'>
				{krepix.map((krep) => (
					<BigCardF
						name={krep.name}
						key={krep.name}
						image={krep.image}
						durability={krep.durability}
						length={krep.durability}
						cellSize={krep.cellSize}
						density={krep.density}
					/>
				))}
			</div>
		</SFacade>
	);
};

export default Facade;
