import { CheckCircle } from "@material-ui/icons";
import React from "react";
import { SAbout } from "./styles";
import { pageMotion } from "../../framer/motions";

const About = () => {
	return (
		<SAbout variants={pageMotion} initial='hidden' animate='show' exit='exit'>
			<div className='title-container'>
				<h2>О Нас</h2>
			</div>
			<div className='quote'>
				<h3>" Создавая с нами, выбирай лучшее! "</h3>
			</div>
			<div className='about about-first'>
				<div className='img-container'>
					<img src='/media/img/about.webp' alt='about' />
				</div>
				<div className='text-container'>
					<p>
						Компания <span>«Mo'jiza Tosh»</span> была основана в
						________________. Начиная с _________________, компания занималась,
						исключительно оптовыми продажами.
					</p>
					<p>
						Мы представили, качественную и разнообразную продукцию от ведущих
						производителей со всего мира.
					</p>
					<p>
						Также в салоне <span>«Mo'jiza Tosh»</span>, на Ваш выбор,
						представлен огромный ассортимент строительно-отделочных материалов в
						Узбекистане.
					</p>
					<p>
						Основная цель магазина — идея, как начало индивидуального
						уникального интерьера с <span>«Mo'jiza Tosh»</span>.
					</p>
				</div>
			</div>
			<div className='about about-second'>
				<div className='img-container'>
					<img src='/media/img/about_2.webp' alt='about' />
				</div>
				<div className='text-container'>
					<p>
						С начала основания, компания <span>«Mo'jiza Tosh»</span> имела
						определенные стратегии и цели развития:
					</p>
					<ul>
						<li>
							<CheckCircle />
							<p>Предоставить лучший сервис для наших клиентов;</p>
						</li>
						<li>
							<CheckCircle />
							<p>Специальные условия работы с дизайнерами;</p>
						</li>
						<li>
							<CheckCircle />
							<p>Представить широкий ассортимент продукции;</p>
						</li>
						<li>
							<CheckCircle />
							<p>Создать умеренные цены и гибкую систему скидок;</p>
						</li>
						<li>
							<CheckCircle />
							<p>
								Расширить географию деятельности нашей компании по всему
								Узбекистану.
							</p>
						</li>
					</ul>
				</div>
			</div>
		</SAbout>
	);
};

export default About;
