import React, { useState } from "react";
import { Link } from "react-router-dom";
import { NavLarge, NavMobile } from "./Navbar/Navbar";
import { BurgerMenu, HeaderFixed, HeaderHeight } from "./styles";

const Header = () => {
	const [navOpen, setNavOpen] = useState(false);
	const [isScrolled, setIsScrolled] = useState(false);
	const navToggler = () => {
		setNavOpen(!navOpen);
	};

	window.onscroll = () => {
		setIsScrolled(window.pageYOffset === 0 ? false : true);
		return () => (window.onscroll = null);
	};

	return (
		<>
			<HeaderHeight />
			<HeaderFixed className={isScrolled ? "scrolled" : ""}>
				<div className='header-container'>
					<div className='logo'>
						<Link to='/' className='logo-link'>
							<img
								src='/media/icon/logo.png'
								alt='logo'
								className='logo-icon'
							/>
						</Link>
					</div>
					<NavLarge />
					<NavMobile navOpen={navOpen} setNavOpen={setNavOpen} />
					<BurgerMenu
						className={`burger-menu ${navOpen ? "close" : "false"}`}
						onClick={navToggler}
					>
						<div className='line'></div>
						<div className='line'></div>
						<div className='line'></div>
						<div className='line'></div>
						<div className='line'></div>
					</BurgerMenu>
				</div>
			</HeaderFixed>
		</>
	);
};

export default Header;
