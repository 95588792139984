import styled from "styled-components";
import { Section } from "../../styles/reusables";
import { v, b, color } from "../../styles/variables";

export const SAbout = styled(Section)`
	padding: 0 ${v.smallDist} 5%;

	.quote {
		text-align: center;
		color: ${color.link};
		margin: ${v.smallDist} 0;
		padding: 0.5rem;
		border-radius: 5px;

		h3 {
			font-weight: 300;
			font-size: 0.9rem;
			letter-spacing: 0.05rem;
		}
	}

	.text-container {
		span {
			font-weight: bold;
			color: ${color.blue};
			text-transform: uppercase;
		}

		li {
			display: flex;
			column-gap: 0.5rem;

			p {
				text-align: left;
			}
		}

		p {
			font-weight: 300;
			line-height: 1.3rem;
			letter-spacing: 0.01rem;
			text-align: justify;
			margin-bottom: 0.5rem;
		}
	}

	@media ${b.medium} {
		padding: 0 ${v.mainMdPd};
	}

	@media ${b.xMedium} {
		.about {
			display: flex;
			column-gap: ${v.mediumDist};
			margin: ${v.mediumDist};
			align-items: center;

			.img-container {
				flex: 40%;

				img {
					height: 300px;
				}
			}

			.text-container {
				flex: 60%;
				align-self: flex-start;
			}

			&.about-second {
				.img-container {
					order: 2;
				}
			}
		}
	}

	@media ${b.xLarge} {
		padding: 0 ${v.mainLargePd};
	}
`;
