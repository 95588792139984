import styled from "styled-components";
import { Section } from "../../styles/reusables";
import { v, b, color } from "../../styles/variables";

export const SContacts = styled(Section)`
	padding: 0 ${v.smallDist} 5%;
	display: grid;
	row-gap: ${v.mediumDist};

	li {
		display: flex;
		column-gap: 0.5rem;
		align-items: center;
		margin-bottom: ${v.smallDist};
	}

	p {
		font-weight: 300;
		line-height: 1.3rem;
		letter-spacing: 0.01rem;
		text-align: justify;
	}

	@media ${b.medium} {
		padding: 0 ${v.mainMdPd};
	}

	@media ${b.large} {
		padding: 0 ${v.mainLargePd};
	}
`;
