import React from "react";
import { SBigCard } from "./styles";

const BigCardF = ({ image, name, durability, density, cellSize, length }) => {
	return (
		<SBigCard>
			<div className='img-container'>
				<img src={image} alt='krepixImg' />
			</div>
			<div className='title-container'>
				<h3>{name}</h3>
			</div>

			<ul className='card-details'>
				<li>
					<p>Прочность на разрыв, H / 5см не менее :</p>{" "}
					<span>{durability}</span>
				</li>
				<li>
					<p>Плотность, г / м ² :</p> <span>{density}</span>
				</li>
				<li>
					<p>Размер ячейки, мм :</p>
					<span>{cellSize}</span>
				</li>
				<li>
					<p>Длина рулона, м :</p>
					<span>{length}</span>
				</li>
			</ul>
		</SBigCard>
	);
};

export default BigCardF;
