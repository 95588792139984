import styled from "styled-components";
import { v, b, t, color } from "../../../styles/variables";
import { motion } from "framer-motion";

export const SNavMobile = styled(motion.nav)`
	position: fixed;
	top: ${v.headerHeight};
	right: 0;
	left: 0;
	bottom: 0;
	background: ${color.lightBg};
	padding: ${v.mediumDist} 0;

	.navbar {
		width: 50%;
		height: 30%;
		margin: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
	}
`;

export const SNavLarge = styled.nav`
	display: none;
	width: 70%;

	@media ${b.medium} {
		display: block;
	}

	@media ${b.large} {
		width: 50%;
	}

	.navbar {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
`;

export const SDropdown = styled.div`
	position: relative;
	user-select: none;

	.d-title {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.arrow {
		&.opened {
			transform: rotate(180deg);
		}
	}

	.dropdown-list {
		position: absolute;
		box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
			rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
		opacity: 0;
		pointer-events: none;
		transition: ${t.fastSp};
		display: flex;
		flex-direction: column;
		top: ${v.mediumDist};
		left: -100%;
		background: ${color.background};
		border-radius: 5px;
		width: max-content;
		gap: ${v.smallDist};
		padding: ${v.smallDist} ${v.mediumDist};

		&.opened {
			opacity: 1;
			pointer-events: all;
		}
	}

	@media ${b.medium} {
		.dropdown-list {
			top: ${v.headerHeight};
			left: -${v.mediumDist};
		}
	}
`;
