import styled from "styled-components";
import { v, b, t, color } from "../../../../styles/variables";

export const SMainContainer = styled.div`
	min-height: calc(100vh - (${v.headerHeight}));
	position: relative;
	display: grid;
	padding: 10% ${v.smallDist};

	.btn-light {
		background: transparent;
		color: ${color.link};
		font-size: 1.1rem;
		padding: 0.5rem ${v.smallDist};
		cursor: pointer;
		transition: ${t.fastSp};
		letter-spacing: 0.06rem;
		border: 1px solid ${color.link};

		&:hover {
			background: ${color.link};
			color: ${color.lightBg};
		}
	}

	.main-content {
		position: relative;
		display: flex;
		flex-direction: column;

		.products-container {
			display: block;
			z-index: 5;
			margin-top: ${v.largeDist};

			.product-list {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-wrap: wrap;
				gap: ${v.smallDist};

				.product-block {
					cursor: pointer;
					user-select: none;
					width: 40%;
					height: 50px;
					background: ${color.background};
					display: flex;
					padding: 0.5rem;
					text-align: center;
					justify-content: center;
					align-items: center;
					transition: ${t.fastSp};

					&.empty {
						display: none;
						background: transparent;
						pointer-events: none;
					}

					&:hover {
						background: ${color.backgroundS};
					}
				}
			}
		}

		.main-title {
			font-size: 3rem;
			font-weight: 700;
			text-align: center;
			padding-bottom: ${v.smallDist};
			color: ${color.text};
		}

		.btn-container {
			margin: ${v.smallDist} 0;
		}
	}

	.pic-container {
		> div {
			user-select: none;
		}

		&.large {
			display: none;
		}
		&.mobile {
			display: block;
		}
		.main-img {
			overflow: hidden;
			img {
				min-height: 50vh;
				object-fit: cover;
				transition: ${t.fastSp};
			}
		}
	}

	@media ${b.medium} {
		padding: 5% ${v.mainMdPd};
	}

	@media ${b.large} {
		grid-template-columns: 1fr 2fr;

		.main-content {
			align-self: flex-start;
			.main-title {
				font-size: 4.5rem;
				line-height: 4rem;
				color: ${color.blue};
			}
			.products-container {
				display: block;
				position: absolute;
				margin-top: ${v.xxLargeDist};
				left: 0;
				top: 70%;
				transform: none;
				.product-list {
					display: grid;
					grid-template: 1fr 1fr 1fr / 1fr 1fr 1fr;
					gap: ${v.smallDist};

					.product-block {
						width: 200px;
						height: 100px;

						&.empty {
							display: block;
						}

						&:hover {
							background: ${color.backgroundS};
						}
					}
				}
			}
		}

		.pic-container {
			justify-self: right;
			position: static;

			&.mobile {
				display: none;
			}
			&.large {
				display: block;
			}

			.main-img {
				max-width: 1200px;
				max-height: 700px;

				img {
					width: 100%;
					height: 100%;
				}
			}
		}
	}
`;
