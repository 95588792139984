import { Facebook, Instagram, Telegram } from "@material-ui/icons";
import React from "react";
import { SFooter } from "./styles";

const Footer = () => {
	return (
		<SFooter>
			<div className='footer-container'>
				<div className='address-container'>
					<h4>Мы находимся по адресу: ""</h4>
				</div>
				<div className='socials'>
					<a href='https://t.me/ASpiritx' rel='noreferrer' target='_blank'>
						<Facebook />
					</a>
					<a href='https://t.me/ASpiritx' rel='noreferrer' target='_blank'>
						<Instagram />
					</a>
					<a href='https://t.me/ASpiritx' rel='noreferrer' target='_blank'>
						<Telegram />
					</a>
				</div>
				<h4>
					Компания Mo'jiza Tosh © 2022.
					<br />
					Все права защищены.
				</h4>
			</div>
		</SFooter>
	);
};

export default Footer;
