import React, { useState } from "react";
import { SWalltex } from "./styles";
import { walltex } from "../../data/prodState";
import SmallCard from "../../components/Cards/SmallCard";
import { pageMotion } from "../../framer/motions";

const Walltex = () => {
	const [wallt] = useState(walltex);
	return (
		<SWalltex variants={pageMotion} initial='hidden' animate='show' exit='exit'>
			<div className='info-container'>
				<div className='title-container-page'>
					<h2>Стеклотканевые обои Walltex</h2>
				</div>

				<div className='content'>
					<div className='img-container'>
						<img src='/media/img/wallperCard3.png' alt='Profitex' />
					</div>
					<div className='text-container'>
						<h3>Стеклотканевые обои плотностью от 140 до 210 г/ м2</h3>
						<p>
							Обои серии Walltex позволяют нам по новому взглянуть на известные
							всем геометрические рисунки. Фактурный объем рогожки, ромба,
							елочки и т. д. откроется в более выигрышном свете, что позволит
							оценить высокое качество материала. Благодаря более плотной
							структуре полотна, эти рисунки будут смотреться на ваших стенах
							более рельефно и эффектно.
						</p>
					</div>
				</div>

				<div className='details-container'>
					<ul>
						<li>
							<p>
								Нетоксичность/огнестойкость: <br /> Класс горючести Г1
							</p>
						</li>
						<li>
							<p>
								Плотность: <br /> от 140 до 210 г / м ²
							</p>
						</li>
						<li>
							<p>
								Ширина рулона: <br /> 1 м
							</p>
						</li>
						<li>
							<p>
								Длина рулона: <br /> 25 м
							</p>
						</li>
					</ul>
				</div>
			</div>
			<div className='product-list'>
				{wallt.map((wall) => (
					<SmallCard
						name={wall.name}
						image={wall.img}
						hq={wall.hq}
						density={wall.density}
						key={wall.name}
						rapport={wall.rapport}
					/>
				))}
			</div>
		</SWalltex>
	);
};

export default Walltex;
