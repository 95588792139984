import styled from "styled-components";
import { PSection } from "../../styles/reusables";
import { color, t, v, b } from "../../styles/variables";

export const SFiberwall = styled(PSection)`
	.wallper-links {
		margin-top: ${v.mediumDist};
		display: flex;
		flex-direction: column;
		align-items: center;
		row-gap: ${v.smallDist};

		.link {
			overflow: hidden;
		}

		img {
			transition: ${t.midSp};

			&:hover {
				transform: scale(1.1);
			}
		}

		@media ${b.medium} {
			flex-wrap: wrap;
			flex-direction: row;
			justify-content: space-around;
		}

		@media ${b.large} {
			flex-wrap: nowrap;
			justify-content: space-between;
			column-gap: ${v.smallDist};
		}
	}

	.advantages {
		margin: ${v.mediumDist} 0;

		.advantage-list {
			display: flex;
			flex-direction: column;
			row-gap: ${v.mediumDist};

			h4 {
				margin-bottom: ${v.smallDist};
			}

			ul {
				li {
					display: flex;
					align-items: flex-start;
					column-gap: 0.5rem;
				}
			}
			@media ${b.xMedium} {
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: space-between;

				> div {
					flex-basis: 45%;
				}
			}
		}
	}

	.useful-sides {
		display: flex;
		flex-direction: column;
		row-gap: ${v.mediumDist};

		.useful {
			display: flex;
			flex-direction: column;
			align-items: center;
			row-gap: ${v.smallDist};

			img {
				max-width: 200px;
			}
		}

		@media ${b.xMedium} {
			flex-direction: row;
			justify-content: space-between;

			> div {
				flex-basis: 30%;
			}
		}
	}

	.application-areas {
		margin-top: ${v.mediumDist};

		.areas {
			display: flex;
			flex-direction: column;
			align-items: center;
			row-gap: ${v.mediumDist};

			.area {
				display: flex;
				flex-direction: column;
				align-items: center;
				row-gap: ${v.smallDist};

				img {
					max-width: 150px;
				}
			}

			@media ${b.xMedium} {
				flex-direction: row;
				column-gap: ${v.smallDist};

				> div {
					flex-basis: 25%;
				}

				.area {
					align-self: stretch;
					p {
						text-align: center;
					}
				}
			}
		}
	}
`;
