import styled from "styled-components";
import { PSection } from "../../styles/reusables";
import { v, b } from "../../styles/variables";

export const SFiberglass = styled(PSection)`
	ul {
		display: flex;
		flex-direction: column;

		li {
			display: flex;
			align-items: flex-start;
			column-gap: 0.5rem;
		}
	}
	.content {
		display: flex;
		flex-direction: column;
		row-gap: ${v.smallDist};

		@media ${b.xMedium} {
			flex-direction: row;
			column-gap: ${v.smallDist};

			.img-container {
				width: 30%;
			}

			.text-container {
				width: 70%;
			}
		}
	}
`;
