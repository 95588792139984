import { createGlobalStyle } from "styled-components";
import { v, b, t, color } from "./variables";

export const GlobalStyles = createGlobalStyle`
    *,
    *::after,
    *::before {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    

    body {
        background: ${color.lightBg};
        color: ${color.text};
        font-family: 'Play', sans-serif;
        font-size: 0.9rem;
        letter-spacing: .05rem;
        overflow-x: hidden;

	    @media ${b.medium} {
		font-size: 1.1rem;
	    }
    }

    h1,h2,h3,h4 {
        color: ${color.lightRed};
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 0.15rem;
    }

    h3 {
		margin-bottom: ${v.mediumDist};
	}

	h4 {
		margin: ${v.smallDist} 0;
	}

    a {
        color: ${color.link};
        text-decoration: none;
    }

    img {
        width: 100%;
    }

    /* button {
        border: none;
        outline: none;
        background: transparent;
        color: ${color.link};
        font-size: 1.1rem;
        padding: 0.5rem ${v.smallDist};
        font-family: 'Play', sans-serif;
        cursor: pointer;
        transition: ${t.fastSp};
        letter-spacing: .06rem;

        &.btn-light {
            border: 1px solid ${color.link};

            &:hover {
                background: ${color.link};
                color: ${color.lightBg};
            }
        }

        @media ${b.medium} {
            font-size: 1.4rem;
        }
    } */

    ul {
        list-style: none;        
    }
`;
