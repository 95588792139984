import styled from "styled-components";
import { color, v, b } from "../../styles/variables";

export const SBigCard = styled.div`
	max-width: 400px;
	height: auto;
	justify-self: center;
	padding: ${v.smallDist};
	background: ${color.lightBg};
	color: ${color.link};
	border-radius: 5px;
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
		rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

	ul {
		@media ${b.xMedium} {
			font-size: 0.9rem;
		}
		li {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 0.5rem;
			border-bottom: 2px solid ${color.link};

			p {
				text-align: left;
				margin: 0;
				flex: 70%;
			}

			span {
				text-align: right;
				flex: 30%;
			}
		}
	}

	.img-container {
		text-align: center;

		img {
			width: 50%;
			border-radius: 50%;
		}
	}
`;

export const SSmallCard = styled.div`
	max-width: 350px;
	cursor: zoom-in;
	width: 100%;
	height: auto;
	justify-self: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: ${v.smallDist};
	padding: ${v.smallDist};
	background: ${color.link};
	color: ${color.background};
	border-radius: 5px;

	.product-name {
		color: ${color.blue};
		font-size: 1.1rem;
		font-weight: 500;
		text-align: center;
	}

	span {
		opacity: 0.8;
	}

	.full-image-container {
		position: fixed;
		top: ${v.headerHeight};
		left: 0;
		bottom: 0;
		right: 0;
		background: #00000099;
		z-index: 10;
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			cursor: zoom-out;
			width: 80vw;
			height: 90vh;
			object-fit: contain;
		}
	}

	.img-container {
		text-align: center;
	}

	@media ${b.xMedium} {
		font-size: 0.9rem;
	}
`;
