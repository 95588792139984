import { CheckBoxOutlined } from "@material-ui/icons";
import React, { useState } from "react";
import { SFiberglass } from "./styles";
import { stekloholstState } from "../../data/prodState";
import BigCardS from "../../components/Cards/BigCardS";
import { pageMotion } from "../../framer/motions";

const Fiberglass = () => {
	const [stekloholst] = useState(stekloholstState);
	return (
		<SFiberglass
			variants={pageMotion}
			initial='hidden'
			animate='show'
			exit='exit'
		>
			<div className='info-container'>
				<div className='title-container-page'>
					<h2>Стеклохолст</h2>
				</div>

				<div className='content'>
					<div className='img-container'>
						<img src='/media/img/stekloholst2.webp' alt='Флизелиновая сетка' />
					</div>
					<div className='text-container'>
						<p>
							Стеклохолст — это универсальный декоративный армирующий материал
							под шпаклевку или покраску, основная функция которого заключается
							в защите поверхностей от трещин. Представляет собой тонкие
							нетканые листы, спрессовынные из нитей стекловолокна
							использованием полимерных добавок. Материал поставляется в
							рулонах, шириной один метр и длиной двадцать или пятьдесят метров
							и плотностью от 25 до 50 г/ м2.
						</p>
						<ul>
							<li>
								<CheckBoxOutlined />
								<p>
									Возможно армирование стен в помещениях не давших усадку или в
									которых возможны механические повреждения
								</p>
							</li>
							<li>
								<CheckBoxOutlined />
								<p>
									Подходит для любых поверхностей: бетон, кирпич, гипсокартон,
									ДСП, пластик или метал
								</p>
							</li>
							<li>
								<CheckBoxOutlined />
								<p>Препятствует образованию плесени</p>
							</li>
							<li>
								<CheckBoxOutlined />
								<p>Возможно многократное окрашивание</p>
							</li>
							<li>
								<CheckBoxOutlined />
								<p>Обеспечивает чистые и герметичные поверхности</p>
							</li>
							<li>
								<CheckBoxOutlined />
								<p>Прост в использовании</p>
							</li>
							<li>
								<CheckBoxOutlined />
								<p>Пропускает воздух и пар</p>
							</li>
							<li>
								<CheckBoxOutlined />
								<p>
									Функционален — можно использовать щелочные моющие средства,
									щётки
								</p>
							</li>
							<li>
								<CheckBoxOutlined />
								<p>
									Непористая структура, нет среды для появления микроэлементов
								</p>
							</li>
						</ul>
					</div>
				</div>
				<div className='advantages'>
					<div className='title-container'>
						<h3>Преимущества и сфера применения</h3>
					</div>
					<p>
						Стеклохолст немецкого производства соответствует европейским
						стандартам качества. Обладая высокой стабильность параметров,
						является одним из самых дешевых материалов для «черновой» отделки
						стен. Использование стеклохолста упрощает процесс отделки, а так же
						скрывает мелкие трещины и неровности. Благодаря стекловолокну,
						стеклохолст является негорючим материалом. Нейтральный цвет, легко
						красится, а небольшой вес рулонов из-за малой плотности материала
						(от 25 до 50 г/м2) удобен для транспортировки частным покупателям.
					</p>
					<p>
						Под покраску используют стеклохолст меньшей плотности, более плотные
						холсты применяют для армирования. Данный материал хорошо скрывает
						микротрещины, но при этом дает возможность стенам дышать. Состав из
						натуральных компонентов (прессованное стекловолокно) не нарушает
						экологическую обстановку и благодаря дышащей структуре способствует
						сохранению микроклимата в помещении.
					</p>
					<p>
						Стеклохолст применяется на бетонных и кирпичных основаниях,
						гипсокартоне и ДСП (ДВП), деревянных и металлических основаниях.
						Наклеивают стеклохолст на потолки и стены. После ремонта штукатурка
						часто дает мелкие трещины, паутинка армирует поверхность,
						предотвращая их появление, скрывает русты, швы между панелями,
						создает ровную гладкую поверхность. У паутинки есть еще и
						«дизайнерская» миссия: с помощью этого материала можно придать
						стенам эффект «мраморности», главное, это правильно подобрать
						краску.
					</p>
				</div>
			</div>
			<div className='product-list'>
				{stekloholst.map((holst) => (
					<BigCardS
						name={holst.name}
						image={holst.img}
						mass={holst.mass}
						massLose={holst.massLose}
						size1={holst.size1}
						size2={holst.size2}
						key={holst.name}
					/>
				))}
			</div>
		</SFiberglass>
	);
};

export default Fiberglass;
