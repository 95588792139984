import styled from "styled-components";
import { PSection } from "../../styles/reusables";
import { v, b } from "../../styles/variables";

export const SInterier = styled(PSection)`
	.sorts {
		display: flex;
		flex-direction: column;
		row-gap: ${v.smallDist};

		@media ${b.medium} {
			flex-direction: row;
			justify-content: space-around;
		}
	}
`;
