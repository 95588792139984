import React from "react";
import { SProfitex } from "./styles";
import { profitex } from "../../data/prodState";
import { useState } from "react";
import SmallCard from "../../components/Cards/SmallCard";
import { pageMotion } from "../../framer/motions";

const Profitex = () => {
	const [profit] = useState(profitex);
	return (
		<SProfitex
			variants={pageMotion}
			initial='hidden'
			animate='show'
			exit='exit'
		>
			<div className='info-container'>
				<div className='title-container-page'>
					<h2>Стеклотканевые обои Profitex</h2>
				</div>

				<div className='content'>
					<div className='img-container'>
						<img src='/media/img/wallperCard2.png' alt='Profitex' />
					</div>
					<div className='text-container'>
						<h3>Стеклотканевые обои плотностью от 70 до 165 г / м ²</h3>
						<p>
							Коллекция Profitex — оптимальное соотношение цены и качества
							продукта, идеально подходит для помещений с большой площадью
							оклеивания. Стеклообои Profitex выбирают для оформления офисов,
							учебных и дошкольных заведений, лечебно-профилактических
							учреждений, производственных, общественных и жилых зданий и
							сооружений. Как производитель мы рекомендуем вам использовать клей
							специально разработанный нами для стеклотканевых обоев.
						</p>
					</div>
				</div>

				<div className='details-container'>
					<ul>
						<li>
							<p>
								Нетоксичность/огнестойкость: <br /> Класс горючести Г1
							</p>
						</li>
						<li>
							<p>
								Плотность: <br /> от 70 до 165 г / м ²
							</p>
						</li>
						<li>
							<p>
								Ширина рулона: <br /> 1 м
							</p>
						</li>
						<li>
							<p>
								Длина рулона: <br /> 25 / 50 м
							</p>
						</li>
					</ul>
				</div>
			</div>
			<div className='product-list'>
				{profit.map((prof) => (
					<SmallCard
						name={prof.name}
						image={prof.img}
						hq={prof.hq}
						density={prof.density}
						key={prof.name}
						rapport={prof.rapport}
					/>
				))}
			</div>
		</SProfitex>
	);
};

export default Profitex;
