export const navMotion = {
	hidden: {
		opacity: 0,
		x: "100%",
		transition: {
			delay: 0.5,
			duration: 0.5,
			staggerChildren: 0.05,
			staggerDirection: -1,
		},
	},
	show: {
		opacity: 1,
		x: "0",
		transition: {
			duration: 0.5,
			staggerChildren: 0.07,
			delayChildren: 0.5,
		},
	},
};

export const pageMotion = {
	hidden: {
		opacity: 0,
	},

	show: {
		opacity: 1,
		transition: {
			duration: 0.5,
			when: "beforeChildren",
			staggerChildren: 0.25,
		},
	},

	exit: {
		opacity: 0,
		transition: {
			duration: 0.5,
		},
	},
};

export const titleMotion = {
	hidden: {
		y: 200,
		opacity: 0,
	},
	show: {
		y: 0,
		opacity: 1,
		transition: { duration: 1, ease: "easeOut" },
	},
};

export const fadeInMotion = {
	hidden: {
		opacity: 0,
	},
	show: {
		opacity: 1,
		transition: { duration: 1, ease: "easeOut" },
	},
	exit: {
		opacity: 0,
	},
};

export const openMotion = {
	hidden: {
		x: 0,
	},
	show: {
		x: -350,
		transition: { duration: 1.2, ease: "easeOut" },
	},
};
