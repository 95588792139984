import React from "react";
import { SBigCard } from "./styles";

const BigCard = ({
	image,
	name,
	mass,
	strengthFirst,
	strengthSecond,
	whiteness,
	opacity,
}) => {
	return (
		<SBigCard>
			<div className='img-container'>
				<img src={image} alt='Flizelin' />
			</div>
			<div className='title-container'>
				<h3>{name}</h3>
			</div>

			<ul className='card-details'>
				<li>
					<p>Поверхностная масса, г / м ²:</p> <span>{mass}</span>
				</li>
				<li>
					<p>Предел прочности на разрыв в предельном состоянии, H / 15 мм :</p>{" "}
					<span>{strengthFirst}</span>
				</li>
				<li>
					<p>Предел прочности на разрыв в предельном состоянии, H / 15 мм :</p>
					<span>{strengthSecond}</span>
				</li>
				<li>
					<p>Белизна, % :</p>
					<span>{whiteness}</span>
				</li>
				<li>
					<p>Непрозрачность, % :</p>
					<span>{opacity}</span>
				</li>
			</ul>
		</SBigCard>
	);
};

export default BigCard;
