import styled from "styled-components";
import { v, b, color } from "./variables";
import { motion } from "framer-motion";

export const Section = styled(motion.section)`
	max-width: 1920px;
	width: 100%;
	min-height: calc(100vh - (${v.headerHeight}));

	.img-container {
		margin-bottom: ${v.smallDist};
	}

	.title-container {
		text-align: center;
		margin: ${v.smallDist} 0;
	}
`;

export const PSection = styled(motion.section)`
	max-width: 1920px;
	width: 100%;
	padding: 0 ${v.smallDist} 5%;

	p {
		font-weight: 500;
		line-height: 1.4rem;
		letter-spacing: 0.01rem;
		text-align: justify;
		margin-bottom: 0.5rem;
	}

	.content {
		margin-bottom: ${v.largeDist};

		.img-container {
			margin-bottom: ${v.smallDist};

			img {
				height: 300px;
				object-fit: contain;
			}
		}
	}

	.title-container {
		text-align: center;
		margin: ${v.smallDist} 0;

		&-page {
			text-align: center;
			margin: ${v.smallDist} 0;
		}

		@media ${b.medium} {
			margin-top: ${v.xLargeDist};
			margin-bottom: ${v.mediumDist};
		}
	}

	.product-list {
		display: grid;
		row-gap: ${v.smallDist};
		margin: ${v.mediumDist} 0;

		@media ${b.xMedium} {
			grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
			grid-gap: ${v.largeDist};

			> div {
				justify-self: center;
			}
		}
	}

	.details-container {
		margin: ${v.smallDist} 0;
		padding: ${v.smallDist} 0;
		border-bottom: 1px solid ${color.link};
		border-top: 1px solid ${color.link};
		ul {
			display: flex;
			flex-wrap: wrap;

			li {
				flex-basis: 50%;
				p {
					font-weight: 500;
				}
			}
		}

		@media ${b.large} {
			ul {
				flex-wrap: nowrap;
				column-gap: ${v.smallDist};
				justify-content: space-between;

				li {
					flex-basis: auto;
				}
			}
		}
	}

	@media ${b.medium} {
		padding: ${v.mediumDist} ${v.mainMdPd};
	}

	@media ${b.large} {
		.content {
			display: flex;
			column-gap: ${v.smallDist};
			margin-bottom: ${v.largeDist};

			.img-container {
				width: 30%;
				display: flex;
				align-items: center;
			}

			.text-container {
				width: 70%;
			}
		}
	}
`;
