export const navItems = [
	{
		title: "Главная",
		link: "/",
	},
	{
		title: "Продукция",
		submenu: [
			{
				title: "Фасадные стеклосетки Крепикс",
				link: "facade",
			},
			{
				title: "Стеклохолсты",
				link: "fiberglass",
			},
			{
				title: "Флизелиновые холсты",
				link: "flizelin",
			},
			{
				title: "Стеклотканевые обои",
				link: "fiberwall",
			},
			{
				title: "Интерьерные сетки",
				link: "interier",
			},
			{
				title: "Жаккардовые обои BauTex Design",
				link: "jacquard",
			},
			{
				title: "Обойный клей",
				link: "wallglue",
			},
		],
	},
	{
		title: "О нас",
		link: "about",
	},
	{
		title: "Контакты",
		link: "contacts",
	},
];
