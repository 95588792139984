import styled from "styled-components";
import { v, b, color, t } from "../../../../styles/variables";

export const SProductContainer = styled.div`
	display: grid;
	overflow: hidden;
	position: relative;

	@media ${b.xMedium} {
		.overlay-left {
			position: absolute;
			z-index: -2;
			top: 0;
			bottom: 0;
			width: 40%;
			height: 100%;
			left: 0;
			background: ${color.link};
		}
	}

	.product {
		padding: 10% ${v.smallDist};
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
		row-gap: ${v.smallDist};
		min-height: 70vh;

		.link-container {
			text-align: center;
		}

		.product-link {
			border: 1px solid ${color.link};
			padding: 0.5rem ${v.smallDist};
			transition: ${t.fastSp};

			&:hover {
				background: ${color.link};
				color: ${color.lightBg};
			}
		}

		.preview-img {
			&.transparent {
				img {
					object-fit: contain;
				}
			}
			z-index: 10;
			width: 100%;
			img {
				object-fit: cover;
				height: 300px;
			}
		}

		.info-container {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			row-gap: ${v.smallDist};
			p {
				font-weight: 300;
				margin-bottom: 1rem;
			}

			h2 {
				font-size: 1.6rem;
				text-align: center;
				position: relative;
				letter-spacing: 0.1rem;
				margin-bottom: ${v.smallDist};

				&::after {
					content: "";
					position: absolute;
					height: 3px;
					background: ${color.link};
					bottom: -0.5rem;
					right: 0;
					left: 0;

					@media ${b.medium} {
						width: 0;
					}
				}
			}
		}

		@media ${b.medium} {
			padding: 5% ${v.mainMdPd};
		}

		@media ${b.xMedium} {
			flex-direction: row;
			min-height: 60vh;
			align-items: center;

			.link-container {
				text-align: left;
			}

			.preview-img {
				width: 40%;

				img {
					height: 400px;
					width: 400px;
				}
			}

			.info-container {
				align-self: flex-start;
				width: 40%;
				position: relative;

				.bg-small {
					position: absolute;
					z-index: -1;
					top: -20%;
					left: -80%;
					width: 180%;
					height: 180%;
					background: ${color.background};
				}

				.bg-medium {
					position: absolute;
					z-index: -1;
					top: -20%;
					left: -90%;
					width: 150%;
					height: 280%;
					background: ${color.background};
				}

				.bg-big {
					position: absolute;
					z-index: -1;
					top: -20%;
					right: 20%;
					width: 220%;
					height: 320%;
					background: ${color.blue};
					opacity: 0;
				}

				h2 {
					text-align: left;
				}
			}
		}
	}
`;
