import { Link } from "react-router-dom";
import { SNavLarge, SNavMobile } from "./styles";
import { navMotion } from "../../../framer/motions";
import { navItems } from "./navItems";
import Dropdown from "./Dropdown";

export const NavMobile = ({ navOpen, setNavOpen }) => {
	return (
		<SNavMobile
			variants={navMotion}
			initial='hidden'
			animate={navOpen ? "show" : "hidden"}
		>
			<ul className='navbar'>
				{navItems.map((items, index) => {
					return (
						<li key={index}>
							{items.submenu ? (
								<Dropdown
									navOpen={navOpen}
									setNavOpen={setNavOpen}
									items={items}
								/>
							) : (
								<Link to={items.link} onClick={() => setNavOpen(!navOpen)}>
									{items.title}
								</Link>
							)}
						</li>
					);
				})}
			</ul>
		</SNavMobile>
	);
};

export const NavLarge = () => {
	return (
		<SNavLarge>
			<ul className='navbar'>
				{navItems.map((items, index) => {
					return (
						<li key={index}>
							{items.submenu ? (
								<Dropdown items={items} />
							) : (
								<Link to={items.link}>{items.title}</Link>
							)}
						</li>
					);
				})}
			</ul>
		</SNavLarge>
	);
};
