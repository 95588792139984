import styled from "styled-components";
import { PSection } from "../../styles/reusables";
import { v, b } from "../../styles/variables";

export const SFlizelin = styled(PSection)`
	.content {
		display: flex;
		flex-direction: column;
		align-items: center;
		row-gap: ${v.smallDist};

		ul {
			display: flex;
			flex-direction: column;

			li {
				display: flex;
				align-items: flex-start;
				column-gap: 0.5rem;
			}
		}
	}

	@media ${b.xMedium} {
		.content {
			flex-direction: row;
			column-gap: ${v.smallDist};

			.img-container {
				width: 30%;
				align-self: flex-start;
			}

			.text-container {
				width: 70%;
			}
		}
	}
`;
