import React from "react";
import MainContainer from "./Container/Main/MainContainer";
import ProductContainer from "./Container/Products/ProductContainer";
import { HomeSection } from "./styles";
import { pageMotion } from "../../framer/motions";

const Home = () => {
	return (
		<HomeSection
			variants={pageMotion}
			initial='hidden'
			animate='show'
			exit='exit'
		>
			<MainContainer />
			<ProductContainer />
		</HomeSection>
	);
};

export default Home;
