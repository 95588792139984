import styled from "styled-components";
import { motion } from "framer-motion";
import { v, t, b, color } from "../../styles/variables";

export const HeaderHeight = styled.div`
	height: ${v.headerHeight};
`;

export const HeaderFixed = styled.header`
	position: fixed;
	left: 0;
	top: 0;
	height: ${v.headerHeight};
	background: ${color.lightBg};
	z-index: 99;
	width: 100vw;
	max-width: 1920px;
	transition: ${t.fastSp};

	&.scrolled {
		box-shadow: 0 3px 5px rgba(78, 78, 78, 0.3);
	}

	.logo-link {
		display: flex;
		justify-content: center;
		align-items: center;

		span {
			font-size: 2.2rem;
			font-weight: 700;
			letter-spacing: 0.05rem;
		}

		.logo-icon {
			width: 150px;
		}
	}

	.header-container {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		padding: 0 ${v.smallDist};

		@media ${b.medium} {
			padding: 0 ${v.mainMdPd};
		}
	}
`;

export const BurgerMenu = styled.div`
	width: 40px;
	height: 40px;
	cursor: pointer;

	@media ${b.medium} {
		display: none;
	}

	.line {
		position: relative;
		height: 4px;
		background: ${color.link};
		margin-bottom: 10px;
		border-radius: 2px;
		transition: ${t.fastSp};

		&:nth-of-type(1) {
			width: 33px;
			transform-origin: left;
		}
		&:nth-of-type(2) {
			right: 0;
			width: 40px;
			transform-origin: right;
		}
		&:nth-of-type(3) {
			margin-bottom: 0;
			transform-origin: left;
			width: 33px;
		}
		&:nth-of-type(4) {
			top: -3px;
			right: 10px;
			margin-bottom: 0;
			transform-origin: right;
			width: 49px;
			transform: rotate(38deg) scaleX(0);
		}
		&:nth-of-type(5) {
			top: -37px;
			right: 10px;
			margin-bottom: 0;
			transform-origin: right;
			width: 49px;
			transform: rotate(322deg) scaleX(0);
		}
	}

	&.close {
		.line {
			&:nth-of-type(1) {
				transform: scaleX(0);
			}
			&:nth-of-type(2) {
				transform: scaleX(0);
			}
			&:nth-of-type(3) {
				transform: scaleX(0);
			}
			&:nth-of-type(4) {
				transform: rotate(38deg) scaleX(1);
				transition-delay: 0.3s;
			}
			&:nth-of-type(5) {
				transform: rotate(322deg) scaleX(1);
				transition-delay: 0.3s;
			}
		}
	}
`;
