import styled from "styled-components";
import { PSection } from "../../styles/reusables";
import { b } from "../../styles/variables";

export const SFacade = styled(PSection)`
	@media ${b.large} {
		.content {
			.text-container {
				width: 60%;
			}
			.img-container {
				width: 40%;
			}
		}
	}
`;
