import React from "react";
import { SSlimtex } from "./styles";
import { slimtex } from "../../data/prodState";
import { useState } from "react";
import SmallCard from "../../components/Cards/SmallCard";
import { pageMotion } from "../../framer/motions";

const Slimtex = () => {
	const [slimt] = useState(slimtex);
	return (
		<SSlimtex variants={pageMotion} initial='hidden' animate='show' exit='exit'>
			<div className='info-container'>
				<div className='title-container-page'>
					<h2>Стеклотканевые обои Slimtex</h2>
				</div>

				<div className='content'>
					<div className='img-container'>
						<img src='/media/img/wallperCard1.png' alt='Slimtex' />
					</div>
					<div className='text-container'>
						<h3>Стеклотканевые обои плотностью от 65 до 125 г/ м ²</h3>
						<p>
							Серия обоев Slimtex — дышащее, прочное финишное покрытие для стен.
							Могут и должны применяться для внутренней отделки помещений с
							постоянным пребыванием людей: жилых домов и общежитий, домов
							отдыха, гостиниц и санаторно-курортных комплексов, закрытых
							спортивных сооружений, предприятий пищевой промышленности,
							торговли и общественного питания, а также вспомогательных и
							бытовых помещений и сооружений, складов и гаражей, лестничных
							пролётов и пожарных выходов. Как производитель мы рекомендуем вам
							использовать клей специально разработанный нами для стеклотканевых
							обоев.
						</p>
					</div>
				</div>

				<div className='details-container'>
					<ul>
						<li>
							<p>
								Нетоксичность/огнестойкость: <br /> Класс горючести Г1
							</p>
						</li>
						<li>
							<p>
								Плотность: <br /> от 65 до 125 г / м ²
							</p>
						</li>
						<li>
							<p>
								Ширина рулона: <br /> 1 м
							</p>
						</li>
						<li>
							<p>
								Длина рулона: <br /> 25 м
							</p>
						</li>
					</ul>
				</div>
			</div>
			<div className='product-list'>
				{slimt.map((slim) => (
					<SmallCard
						name={slim.name}
						image={slim.img}
						hq={slim.hq}
						density={slim.density}
						key={slim.name}
					/>
				))}
			</div>
		</SSlimtex>
	);
};

export default Slimtex;
