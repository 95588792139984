import amsterdam from "./media/img/bautexDesign/amsterdam.jpg";
import angel from "./media/img/bautexDesign/angel.jpg";
import barcelona from "./media/img/bautexDesign/barcelona.jpg";
import berlin from "./media/img/bautexDesign/berlin.jpg";
import dublin from "./media/img/bautexDesign/dublin.jpg";
import hyuston from "./media/img/bautexDesign/hyuston.jpg";
import boston from "./media/img/bautexDesign/boston.jpg";
import lion from "./media/img/bautexDesign/lion.jpg";
import london from "./media/img/bautexDesign/london.jpg";
import madagaskar from "./media/img/bautexDesign/madagaskar.jpg";
import manchester from "./media/img/bautexDesign/manchester.jpg";
import milan from "./media/img/bautexDesign/milan.jpg";
import nairobi from "./media/img/bautexDesign/nairobi.jpg";
import nice from "./media/img/bautexDesign/nice.jpg";
import newyork from "./media/img/bautexDesign/nyu-uork.jpg";
import paris from "./media/img/bautexDesign/paris.jpg";
import praga from "./media/img/bautexDesign/praga.jpg";
import provence from "./media/img/bautexDesign/provence.jpg";
import rim from "./media/img/bautexDesign/rim.jpg";
import rodos from "./media/img/bautexDesign/rodos.jpg";
import singapur from "./media/img/bautexDesign/singapur.jpg";
import sofia from "./media/img/bautexDesign/sofia.jpg";
import stambul from "./media/img/bautexDesign/stambul.jpg";
import sydney from "./media/img/bautexDesign/sydney.jpg";
import tokio from "./media/img/bautexDesign/tokio.jpg";
import valencia from "./media/img/bautexDesign/valencia.jpg";
import venetsiya from "./media/img/bautexDesign/venetsiya.jpg";
import zheneva from "./media/img/bautexDesign/zheneva.jpg";
import amsterdamHQ from "./media/img/bautexDesign/HQ/amsterdamHQ.webp";
import angelHQ from "./media/img/bautexDesign/HQ/angelHQ.webp";
import barcelonaHQ from "./media/img/bautexDesign/HQ/barcelonaHQ.webp";
import berlinHQ from "./media/img/bautexDesign/HQ/berlinHQ.webp";
import bostonHQ from "./media/img/bautexDesign/HQ/bostonHQ.webp";
import dublinHQ from "./media/img/bautexDesign/HQ/dublinHQ.webp";
import genevaHQ from "./media/img/bautexDesign/HQ/genevaHQ.webp";
import houstonHQ from "./media/img/bautexDesign/HQ/houstonHQ.webp";
import istanbulHQ from "./media/img/bautexDesign/HQ/istanbulHQ.webp";
import lionHQ from "./media/img/bautexDesign/HQ/lionHQ.webp";
import lisbonHQ from "./media/img/bautexDesign/HQ/lisbonHQ.webp";
import londonHQ from "./media/img/bautexDesign/HQ/londonHQ.webp";
import madagaskarHQ from "./media/img/bautexDesign/HQ/madagaskarHQ.webp";
import manchesterHQ from "./media/img/bautexDesign/HQ/manchesterHQ.webp";
import milanHQ from "./media/img/bautexDesign/HQ/milanHQ.webp";
import nairobiHQ from "./media/img/bautexDesign/HQ/nairobiHQ.webp";
import newyorkHQ from "./media/img/bautexDesign/HQ/newyorkHQ.webp";
import niceHQ from "./media/img/bautexDesign/HQ/niceHQ.webp";
import parisHQ from "./media/img/bautexDesign/HQ/parisHQ.webp";
import pragueHQ from "./media/img/bautexDesign/HQ/pragueHQ.webp";
import provenceHQ from "./media/img/bautexDesign/HQ/provenceHQ.webp";
import rodosHQ from "./media/img/bautexDesign/HQ/rodosHQ.webp";
import romeHQ from "./media/img/bautexDesign/HQ/romeHQ.webp";
import singaporeHQ from "./media/img/bautexDesign/HQ/singaporeHQ.webp";
import sofiaHQ from "./media/img/bautexDesign/HQ/sofiaHQ.webp";
import sydneyHQ from "./media/img/bautexDesign/HQ/sydneyHQ.webp";
import tokyoHQ from "./media/img/bautexDesign/HQ/tokyoHQ.webp";
import valenciaHQ from "./media/img/bautexDesign/HQ/valenciaHQ.webp";
import veniceHQ from "./media/img/bautexDesign/HQ/veniceHQ.webp";
import flizelinImg from "./media/img/fliz1.png";
import krepix from "./media/img/krepix/krepix_1.png";
import krepix2600 from "./media/img/krepix/krepiks_2600.jpg";
import krepix320 from "./media/img/krepix/krepiks_320.jpg";
import krepixArch from "./media/img/krepix/krepiks_arh.jpg";
import slimtex1 from "./media/img/stekloWallper/slimtex/slimtex1.png";
import slimtex2 from "./media/img/stekloWallper/slimtex/slimtex2.png";
import slimtex3 from "./media/img/stekloWallper/slimtex/slimtex3.png";
import slimtex4 from "./media/img/stekloWallper/slimtex/slimtex4.png";
import slimtex1HQ from "./media/img/stekloWallper/slimtexHQ/slimtex1HQ.webp";
import slimtex2HQ from "./media/img/stekloWallper/slimtexHQ/slimtex2HQ.webp";
import slimtex3HQ from "./media/img/stekloWallper/slimtexHQ/slimtex3HQ.webp";
import slimtex4HQ from "./media/img/stekloWallper/slimtexHQ/slimtex4HQ.webp";
import slimtex5HQ from "./media/img/stekloWallper/slimtexHQ/slimtex4HQ.webp";
import profitex1 from "./media/img/stekloWallper/profitex/profitex1.png";
import profitex2 from "./media/img/stekloWallper/profitex/profitex2.png";
import profitex3 from "./media/img/stekloWallper/profitex/profitex3.png";
import profitex4 from "./media/img/stekloWallper/profitex/profitex4.png";
import profitex5 from "./media/img/stekloWallper/profitex/profitex5.png";
import profitex6 from "./media/img/stekloWallper/profitex/profitex6.png";
import profitex7 from "./media/img/stekloWallper/profitex/profitex7.png";
import profitex8 from "./media/img/stekloWallper/profitex/profitex8.png";
import profitex9 from "./media/img/stekloWallper/profitex/profitex9.png";
import profitex10 from "./media/img/stekloWallper/profitex/profitex10.png";
import profitex11 from "./media/img/stekloWallper/profitex/profitex11.png";
import profitex12 from "./media/img/stekloWallper/profitex/profitex12.png";
import profitex13 from "./media/img/stekloWallper/profitex/profitex13.png";
import profitex14 from "./media/img/stekloWallper/profitex/profitex14.png";
import profitex1HQ from "./media/img/stekloWallper/profitexHQ/profitex1HQ.webp";
import profitex2HQ from "./media/img/stekloWallper/profitexHQ/profitex2HQ.webp";
import profitex3HQ from "./media/img/stekloWallper/profitexHQ/profitex3HQ.webp";
import profitex4HQ from "./media/img/stekloWallper/profitexHQ/profitex4HQ.webp";
import profitex5HQ from "./media/img/stekloWallper/profitexHQ/profitex5HQ.webp";
import profitex6HQ from "./media/img/stekloWallper/profitexHQ/profitex6HQ.webp";
import profitex7HQ from "./media/img/stekloWallper/profitexHQ/profitex7HQ.webp";
import profitex8HQ from "./media/img/stekloWallper/profitexHQ/profitex8HQ.webp";
import profitex9HQ from "./media/img/stekloWallper/profitexHQ/profitex9HQ.webp";
import profitex10HQ from "./media/img/stekloWallper/profitexHQ/profitex10HQ.webp";
import profitex11HQ from "./media/img/stekloWallper/profitexHQ/profitex11HQ.webp";
import profitex12HQ from "./media/img/stekloWallper/profitexHQ/profitex12HQ.webp";
import profitex13HQ from "./media/img/stekloWallper/profitexHQ/profitex13HQ.webp";
import profitex14HQ from "./media/img/stekloWallper/profitexHQ/profitex14HQ.webp";
import profitex15HQ from "./media/img/stekloWallper/profitexHQ/profitex15HQ.webp";
import profitex16HQ from "./media/img/stekloWallper/profitexHQ/profitex16HQ.webp";
import walltex1 from "./media/img/stekloWallper/walltex/walltex1.png";
import walltex2 from "./media/img/stekloWallper/walltex/walltex2.png";
import walltex3 from "./media/img/stekloWallper/walltex/walltex3.png";
import walltex4 from "./media/img/stekloWallper/walltex/walltex4.png";
import walltex5 from "./media/img/stekloWallper/walltex/walltex5.png";
import walltex6 from "./media/img/stekloWallper/walltex/walltex6.png";
import walltex7 from "./media/img/stekloWallper/walltex/walltex7.png";
import walltex8 from "./media/img/stekloWallper/walltex/walltex8.png";
import walltex9 from "./media/img/stekloWallper/walltex/walltex9.png";
import walltex10 from "./media/img/stekloWallper/walltex/walltex10.png";
import walltex11 from "./media/img/stekloWallper/walltex/walltex11.png";
import walltex12 from "./media/img/stekloWallper/walltex/walltex12.png";
import walltex13 from "./media/img/stekloWallper/walltex/walltex13.png";
import walltex14 from "./media/img/stekloWallper/walltex/walltex14.jpg";
import walltex15 from "./media/img/stekloWallper/walltex/walltex15.png";
import walltex16 from "./media/img/stekloWallper/walltex/walltex16.png";
import walltex17 from "./media/img/stekloWallper/walltex/walltex17.png";
import walltex18 from "./media/img/stekloWallper/walltex/walltex18.png";
import walltex19 from "./media/img/stekloWallper/walltex/walltex19.png";
import walltex1HQ from "./media/img/stekloWallper/walltexHQ/walltex1HQ.webp";
import walltex2HQ from "./media/img/stekloWallper/walltexHQ/walltex2HQ.webp";
import walltex3HQ from "./media/img/stekloWallper/walltexHQ/walltex3HQ.webp";
import walltex4HQ from "./media/img/stekloWallper/walltexHQ/walltex4HQ.webp";
import walltex5HQ from "./media/img/stekloWallper/walltexHQ/walltex5HQ.webp";
import walltex6HQ from "./media/img/stekloWallper/walltexHQ/walltex6HQ.webp";
import walltex7HQ from "./media/img/stekloWallper/walltexHQ/walltex7HQ.webp";
import walltex8HQ from "./media/img/stekloWallper/walltexHQ/walltex8HQ.webp";
import walltex9HQ from "./media/img/stekloWallper/walltexHQ/walltex9HQ.webp";
import walltex10HQ from "./media/img/stekloWallper/walltexHQ/walltex10HQ.webp";
import walltex11HQ from "./media/img/stekloWallper/walltexHQ/walltex11HQ.webp";
import walltex12HQ from "./media/img/stekloWallper/walltexHQ/walltex12HQ.webp";
import walltex13HQ from "./media/img/stekloWallper/walltexHQ/walltex13HQ.webp";
import walltex14HQ from "./media/img/stekloWallper/walltexHQ/walltex14HQ.webp";
import walltex15HQ from "./media/img/stekloWallper/walltexHQ/walltex15HQ.webp";
import walltex16HQ from "./media/img/stekloWallper/walltexHQ/walltex16HQ.webp";
import walltex17HQ from "./media/img/stekloWallper/walltexHQ/walltex17HQ.webp";
import walltex18HQ from "./media/img/stekloWallper/walltexHQ/walltex18HQ.webp";
import walltex19HQ from "./media/img/stekloWallper/walltexHQ/walltex19HQ.webp";
import stekloholstImg from "./media/img/stekloholst_1.png";

export const bautexDesignState = () => {
	return [
		{
			name: "Dublin by BauTex Design",
			img: dublin,
			hq: dublinHQ,
			density: "260 г / м ²",
		},
		{
			name: "Prague by BauTex Design",
			img: praga,
			hq: pragueHQ,
			density: "260 г / м ²",
			rapport: "32 см",
		},
		{
			name: "London by BauTex Design",
			img: london,
			hq: londonHQ,
			density: "280 г / м ²",
			rapport: "2,7 см",
		},
		{
			name: "Rome by BauTex Design",
			img: rim,
			hq: romeHQ,
			density: "260 г / м ²",
			rapport: "1,5 см",
		},
		{
			name: "Tokyo by BauTex Design",
			img: tokio,
			hq: tokyoHQ,
			density: "255 г / м ²",
			rapport: "100 см",
		},
		{
			name: "New York by BauTex Design",
			img: newyork,
			hq: newyorkHQ,
			density: "255 г / м ²",
			rapport: "100 см",
		},
		{
			name: "Berlin by BauTex Design",
			img: berlin,
			hq: berlinHQ,
			density: "185 г / м ²",
			rapport: "8,5 см",
		},
		{
			name: "Venice by BauTex Design",
			img: venetsiya,
			hq: veniceHQ,
			density: "275 г / м ²",
			rapport: "2,4 см",
		},
		{
			name: "Geneva by BauTex Design",
			img: zheneva,
			hq: genevaHQ,
			density: "270 г / м ²",
		},
		{
			name: "Houston by BauTex Design (пигментированный холст)",
			img: hyuston,
			hq: houstonHQ,
			density: "200 г / м ²",
		},
		{
			name: "Boston by BauTex Design (пигментированный холст)",
			img: boston,
			hq: bostonHQ,
			density: "180 г / м ²",
		},
		{
			name: "Istanbul by BauTex Design",
			img: stambul,
			hq: istanbulHQ,
			density: "270 г / м ²",
			rapport: "66 см",
		},
		{
			name: "Singapore by BauTex Design",
			img: singapur,
			hq: singaporeHQ,
			density: "270 г / м ²",
			rapport: "80 см",
		},
		{
			name: "Rodos by BauTex Design",
			img: rodos,
			hq: rodosHQ,
			density: "270 г / м ²",
			rapport: "46 см",
		},
		{
			name: "Lux22 Manchester",
			img: manchester,
			hq: manchesterHQ,
			density: "270 г / м ²",
			rapport: "73 см",
		},
		{
			name: "Lux22 Provence",
			img: provence,
			hq: provenceHQ,
			density: "255 г / м ²",
			rapport: "61 см",
		},
		{
			name: "Lux4 Sydney",
			img: sydney,
			hq: sydneyHQ,
			density: "255 г / м ²",
		},
		{
			name: "Lux10 Paris",
			img: paris,
			hq: parisHQ,
			density: "275 г / м ²",
			rapport: "5,5 см",
		},
		{
			name: "Lux11 Milan",
			img: milan,
			hq: milanHQ,
			density: "255 г / м ²",
		},
		{
			name: "Lux12 Amsterdam",
			img: amsterdam,
			hq: amsterdamHQ,
			density: "255 г / м ²",
			rapport: "42 см",
		},
		{
			name: "Lux17 Angel",
			img: angel,
			hq: angelHQ,
			density: "255 г / м ²",
			rapport: "150 см",
		},
		{
			name: "Lux18 Nice",
			img: nice,
			hq: niceHQ,
			density: "255 г / м ²",
			rapport: "50 см",
		},
		{
			name: "Lux18 Madagascar",
			img: madagaskar,
			hq: madagaskarHQ,
			density: "255 г / м ²",
		},
		{
			name: "Lux20 Nairobi",
			img: nairobi,
			hq: nairobiHQ,
			density: "255 г / м ²",
			rapport: "77,5 см",
		},
		{
			name: "Lux13 Barcelona",
			img: barcelona,
			hq: barcelonaHQ,
			density: "270 г / м ²",
			rapport: "33,1 см",
		},
		{
			name: "Lux14 Sofia",
			img: sofia,
			hq: sofiaHQ,
			density: "255 г / м ²",
			rapport: "6,4 см",
		},
		{
			name: "Lux15 Valencia",
			img: valencia,
			hq: valenciaHQ,
			density: "255 г / м ²",
			rapport: "112 см",
		},
		{
			name: "Lux16 Lion",
			img: lion,
			hq: lionHQ,
			density: "255 г / м ²",
			rapport: "60 см",
		},
	];
};

export const flizelinState = () => {
	return [
		{
			img: flizelinImg,
			name: "65",
			mass: "65±5",
			strengthFirst: "50",
			strengthSecond: "25",
			whiteness: "85",
			opacity: "70",
		},
		{
			img: flizelinImg,
			name: "85",
			mass: "85±5",
			strengthFirst: "90",
			strengthSecond: "45",
			whiteness: "85",
			opacity: "70",
		},
		{
			img: flizelinImg,
			name: "110",
			mass: "110±5",
			strengthFirst: "110",
			strengthSecond: "50",
			whiteness: "85",
			opacity: "70",
		},
		{
			img: flizelinImg,
			name: "130",
			mass: "130±5",
			strengthFirst: "120",
			strengthSecond: "55",
			whiteness: "85",
			opacity: "70",
		},
		{
			img: flizelinImg,
			name: "150",
			mass: "150±6",
			strengthFirst: "130",
			strengthSecond: "60",
			whiteness: "85",
			opacity: "70",
		},
		{
			img: flizelinImg,
			name: "175",
			mass: "175±5",
			strengthFirst: "150",
			strengthSecond: "85",
			whiteness: "85",
			opacity: "70",
		},
	];
};

export const krepixState = () => {
	return [
		{
			name: "Крепикс 1300",
			image: krepix,
			durability: "1300 / 1300",
			density: "104 ± 7%",
			cellSize: "4,5 x 4,5",
			length: "50",
		},
		{
			name: "Крепикс 1500",
			image: krepix,
			durability: "1500 / 1500",
			density: "139 ± 9%",
			cellSize: "5,0 x 5,0",
			length: "50",
		},
		{
			name: "Крепикс 1800",
			image: krepix,
			durability: "1800 / 1800",
			density: "145 ± 7%",
			cellSize: "5,0 x 4,2",
			length: "50",
		},
		{
			name: "Крепикс 2000",
			image: krepix,
			durability: "2000 / 2000",
			density: "158 ± 7%",
			cellSize: "4,0 x 4,2",
			length: "50",
		},
		{
			name: "Крепикс 2600",
			image: krepix2600,
			durability: "2600 / 2600",
			density: "200 ± 7%",
			cellSize: "8,0 x 8,0",
			length: "50",
		},
		{
			name: "Крепикс 320 Панцирная",
			image: krepix320,
			durability: "3600 / 3600",
			density: "320 ± 5%",
			cellSize: "8,5 x 8,5",
			length: "25",
		},
		{
			name: "Крепикс 80 Архитектурная",
			image: krepixArch,
			durability: "1100 / 500",
			density: "77 ± 7%",
			cellSize: "3,0 x 3,0",
			length: "50",
		},
	];
};

export const slimtex = () => {
	return [
		{
			name: "S12 Рогожка средняя",
			density: "65 г / м ²",
			img: slimtex1,
			hq: slimtex1HQ,
		},
		{
			name: "S16 Рогожка средняя",
			density: "110 г / м ²",
			img: slimtex1,
			hq: slimtex2HQ,
		},
		{
			name: "S83 Елка средняя",
			density: "125 г / м ²",
			img: slimtex2,
			hq: slimtex3HQ,
		},
		{
			name: "S93 Ромб средний",
			density: "125 г / м ²",
			img: slimtex3,
			hq: slimtex4HQ,
		},
		{
			name: "S100 Рогожка потолочная",
			density: "100 г / м ²",
			img: slimtex4,
			hq: slimtex5HQ,
		},
	];
};

export const profitex = () => {
	return [
		{
			name: "P12 Рогожка средняя",
			density: "70 г / м ²",
			img: profitex1,
			hq: profitex1HQ,
		},
		{
			name: "P16 Рогожка средняя",
			density: "115 г / м ²",
			img: profitex1,
			hq: profitex2HQ,
		},
		{
			name: "P17 Рогожка средняя",
			density: "125 г / м ²",
			img: profitex1,
			hq: profitex3HQ,
		},
		{
			name: "P30 Рогожка крупная",
			density: "125 г / м ²",
			img: profitex2,
			rapport: "2,5 см",
			hq: profitex4HQ,
		},
		{
			name: "P40 Паркет",
			density: "160 г / м ²",
			img: profitex3,
			rapport: "4,8 см",
			hq: profitex5HQ,
		},
		{
			name: "P60 Диагональ средняя",
			density: "160 г / м ²",
			img: profitex4,
			rapport: "1,6 см",
			hq: profitex6HQ,
		},
		{
			name: "P65 Ампир",
			density: "160 г / м ²",
			img: profitex5,
			rapport: "1,6 см",
			hq: profitex7HQ,
		},
		{
			name: "P70 Зигзаг",
			density: "155 г / м ²",
			img: profitex6,
			rapport: "1,6 см",
			hq: profitex8HQ,
		},
		{
			name: "P80 Модерн",
			density: "130 г / м ²",
			img: profitex7,
			hq: profitex9HQ,
		},
		{
			name: "P82 Елочка мелкая",
			density: "120 г / м ²",
			img: profitex8,
			rapport: "2,5 см",
			hq: profitex10HQ,
		},
		{
			name: "P85 Елочка средняя",
			density: "165 г / м ²",
			img: profitex9,
			rapport: "1,6 см",
			hq: profitex11HQ,
		},
		{
			name: "P91 Ромб",
			density: "165 г / м ²",
			img: profitex10,
			rapport: "8 см",
			hq: profitex12HQ,
		},
		{
			name: "P92 Ромб мелкий",
			density: "160 г / м ²",
			img: profitex11,
			rapport: "3,2 см",
			hq: profitex13HQ,
		},
		{
			name: "P95 Ромб особый",
			density: "160 г / м ²",
			img: profitex12,
			rapport: "6,4 см",
			hq: profitex14HQ,
		},
		{
			name: "P100 Рогожка потолочная",
			density: "110 г / м ²",
			img: profitex13,
			hq: profitex15HQ,
		},
		{
			name: "P200 Оазис",
			density: "155 г / м ²",
			img: profitex14,
			rapport: "5,6 см",
			hq: profitex16HQ,
		},
	];
};

export const walltex = () => {
	return [
		{
			name: "W16 Рогожка средняя",
			density: "140 г / м ²",
			img: walltex1,
			hq: walltex1HQ,
		},
		{
			name: "W18 Рогожка средняя",
			density: "150 г / м ²",
			img: walltex2,
			hq: walltex2HQ,
		},
		{
			name: "W20 Сеточка",
			density: "105 г / м ²",
			img: walltex3,
			hq: walltex3HQ,
		},
		{
			name: "W25 Рогожка ср. частая",
			density: "175 г / м ²",
			img: walltex4,
			hq: walltex4HQ,
		},
		{
			name: "W30 Дерюжка крупная",
			density: "205 г / м ²",
			img: walltex5,
			hq: walltex5HQ,
		},
		{
			name: "W31 Шашечки",
			density: "185 г / м ²",
			img: walltex6,
			rapport: "2,4 см",
			hq: walltex6HQ,
		},
		{
			name: "W35 Вертикаль",
			density: "160 г / м ²",
			img: walltex7,
			rapport: "34,8 см",
			hq: walltex7HQ,
		},
		{
			name: "W40 Паркет",
			density: "175 г / м ²",
			img: walltex8,
			rapport: "4,8 см",
			hq: walltex8HQ,
		},
		{
			name: "W50 Рогожка крупная",
			density: "210 г / м ²",
			img: walltex9,
			rapport: "2,5 см",
			hq: walltex9HQ,
		},
		{
			name: "W55 Рисовая бумага",
			density: "175 г / м ²",
			img: walltex10,
			rapport: "6,4 см",
			hq: walltex10HQ,
		},
		{
			name: "W60 Диагональ средняя",
			density: "175 г / м ²",
			img: walltex11,
			rapport: "1,6 см",
			hq: walltex11HQ,
		},
		{
			name: "W65 Ампир",
			density: "180 г / м ²",
			img: walltex12,
			rapport: "1,6 см",
			hq: walltex12HQ,
		},
		{
			name: "W70 Зиг-заг",
			density: "180 г / м ²",
			img: walltex13,
			rapport: "1,6 см",
			hq: walltex13HQ,
		},
		{
			name: "W80 Модерн",
			density: "175 г / м ²",
			img: walltex14,
			hq: walltex14HQ,
		},
		{
			name: "W85 Елочка средняя",
			density: "180 г / м ²",
			img: walltex15,
			rapport: "1,6 см",
			hq: walltex15HQ,
		},
		{
			name: "W89 Елка крупная",
			density: "185 г / м ²",
			img: walltex16,
			rapport: "2,4 см",
			hq: walltex16HQ,
		},
		{
			name: "W91 Ромб средний",
			density: "180 г / м ²",
			img: walltex17,
			rapport: "8 см",
			hq: walltex17HQ,
		},
		{
			name: "W100 Рогожка потолочная",
			density: "155 г / м ²",
			img: walltex18,
			hq: walltex18HQ,
		},
		{
			name: "W200 Оазис",
			density: "170 г / м ²",
			img: walltex19,
			rapport: "5,6 см",
			hq: walltex19HQ,
		},
	];
};

export const stekloholstState = () => {
	return [
		{
			img: stekloholstImg,
			name: "25 JM",
			mass: "25",
			massLose: "23",
			size1: "≥ 70",
			size2: "≥ 50",
		},
		{
			img: stekloholstImg,
			name: "40 JM",
			mass: "35-45",
			massLose: "12-31",
			size1: "≥ 70",
			size2: "≥ 55",
		},
		{
			img: stekloholstImg,
			name: "45 JM",
			mass: "35-45",
			massLose: "12-31",
			size1: "≥ 70",
			size2: "≥ 55",
		},
		{
			img: stekloholstImg,
			name: "50 JM",
			mass: "45-55",
			massLose: "15-31",
			size1: "≥ 120",
			size2: "≥ 90",
		},
	];
};
