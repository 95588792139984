import styled from "styled-components";
import { PSection } from "../../styles/reusables";
import { color, b } from "../../styles/variables";

export const SWallGlue = styled(PSection)`
	.special {
		color: ${color.blue};
		text-align: center;

		@media ${b.medium} {
			text-align: start;
		}
	}

	li {
		display: flex;
		column-gap: 0.5rem;

		p {
			text-align: left;
		}
	}

	.img-container {
		.extra {
			height: 500px;
		}
	}

	@media ${b.large} {
		.content {
			align-items: center;
		}
	}
`;
