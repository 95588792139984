import React, { useEffect } from "react";
import { GlobalStyles } from "./styles/globalStyles";
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Header from "./components/Header/Header";
import Home from "./pages/Main/Home";
import Flizelin from "./pages/Flizelin/Flizelin";
import Interier from "./pages/Interier/Interier";
import Slimtex from "./pages/Slimtex/Slimtex";
import Walltex from "./pages/Walltex/Walltex";
import Fiberglass from "./pages/Stekloholst/Fiberglass";
import Fiberwall from "./pages/Steklooboi/Fiberwall";
import Facade from "./pages/Steklosetka/Facade";
import Jacquard from "./pages/Jakkard/Jacquard";
import Profitex from "./pages/Profitex/Profitex";
import Footer from "./components/Header/Footer/Footer";
import About from "./pages/About/About";
import Contacts from "./pages/Contacts/Contacts";
import Wallglue from "./pages/Wallglue/Wallglue";

const App = () => {
	const location = useLocation();

	useEffect(() => {
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 500);
	}, [location.pathname]);
	return (
		<>
			<GlobalStyles />
			<Header />
			<AnimatePresence exitBeforeEnter>
				<Routes location={location} key={location.pathname}>
					<Route path='/' element={<Home />} />
					<Route path='flizelin' element={<Flizelin />} />
					<Route path='interier' element={<Interier />} />
					<Route path='slimtex' element={<Slimtex />} />
					<Route path='walltex' element={<Walltex />} />
					<Route path='profitex' element={<Profitex />} />
					<Route path='fiberglass' element={<Fiberglass />} />
					<Route path='fiberwall' element={<Fiberwall />} />
					<Route path='facade' element={<Facade />} />
					<Route path='jacquard' element={<Jacquard />} />
					<Route path='about' element={<About />} />
					<Route path='contacts' element={<Contacts />} />
					<Route path='wallglue' element={<Wallglue />} />
				</Routes>
			</AnimatePresence>
			<Footer />
		</>
	);
};

export default App;
