import {
	AlternateEmail,
	Business,
	Call,
	Facebook,
	Instagram,
	Telegram,
} from "@material-ui/icons";
import React from "react";
import { SContacts } from "./styles";
import { pageMotion } from "../../framer/motions";

const Contacts = () => {
	return (
		<SContacts
			variants={pageMotion}
			initial='hidden'
			animate='show'
			exit='exit'
		>
			<div className='title-container'>
				<h2>Наши контактные данные</h2>
			</div>
			<div className='contacts'>
				<ul>
					<li>
						<Business />
						<p>Адрес: </p>
					</li>
					<li>
						<Call />
						<p>Номер #1:</p>
					</li>
					<li>
						<Call />
						<p>Номер #2:</p>
					</li>
					<li>
						<AlternateEmail />
						<p>E-mail:</p>
					</li>
				</ul>
			</div>
			<div className='socials'>
				<div className='title-container'>
					<h3>Ссылки на социальные страницы</h3>
				</div>
				<ul>
					<li>
						<Telegram />
						<p>Telegram</p>
					</li>
					<li>
						<Instagram />
						<p>Instagram</p>
					</li>
					<li>
						<Facebook />
						<p>Facebook</p>
					</li>
				</ul>
			</div>
			<div className='location'>
				<h3>Location Map placeholder</h3>
			</div>
		</SContacts>
	);
};

export default Contacts;
