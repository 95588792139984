export const v = {
	headerHeight: "4rem",
	smallDist: "1rem",
	mediumDist: "2rem",
	largeDist: "3rem",
	xLargeDist: "4rem",
	xxLargeDist: "5rem",
	mainMdPd: "10%",
	mainLargePd: "20%",
};

export const b = {
	medium: `(min-width: 600px)`,
	xMedium: `(min-width: 900px)`,
	large: `(min-width: 1200px)`,
	xLarge: `(min-width: 1600px)`,
};

export const t = {
	fastSp: `all 0.3s ease`,
	midSp: `all 0.5s ease`,
	lowSp: `all 1s ease`,
};

export const color = {
	background: "#E0E5EB",
	backgroundS: "#afafaf",
	text: "#111111",
	blue: "#C7AF97",
	link: "#111111",
	lightBg: "#F8FAF9",
};
