import { ArrowDropDown } from "@material-ui/icons";
import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { SDropdown } from "./styles";

const Dropdown = ({ items, navOpen, setNavOpen }) => {
	const [dropdown, setDropdown] = useState(false);

	return (
		<SDropdown>
			<Link to='#' className='d-title' onClick={() => setDropdown(!dropdown)}>
				{items.title}
				<ArrowDropDown className={`arrow ${dropdown ? "opened" : ""}`} />
			</Link>
			<ul className={`dropdown-list ${dropdown ? "opened" : ""}`}>
				{items.submenu.map((item, index) => {
					return (
						<li key={index}>
							<Link
								to={item.link}
								onClick={() => {
									setDropdown(!dropdown);
									if (window.innerWidth < 600) {
										setNavOpen(!navOpen);
									}
								}}
							>
								{item.title}
							</Link>
						</li>
					);
				})}
			</ul>
		</SDropdown>
	);
};

export default Dropdown;
